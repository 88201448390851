import type { FC } from "react"
import PropTypes from "prop-types"
import { AppBar, Box, Grid, IconButton, Toolbar } from "@material-ui/core"
import { experimentalStyled } from "@material-ui/core/styles"
import type { AppBarProps } from "@material-ui/core"
import MenuIcon from "../../icons/Menu"
import AccountPopover from "./AccountPopover"
import Logo from "../Logo"
import { TOOLBAR_HEIGHT } from "./DashboardLayout"

interface DashboardNavbarProps extends AppBarProps {
  customOpenSidebar?: () => void
  setApiKeyModalOpen?: any
}

const DashboardNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === "light" && {
    backgroundColor: theme.palette.primary.main,
    boxShadow: "none",
    color: theme.palette.primary.contrastText,
  }),
  ...(theme.palette.mode === "dark" && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: "none",
  }),
}))

const DashboardNavbar: FC<DashboardNavbarProps> = (props) => {
  const { customOpenSidebar, setApiKeyModalOpen, ...other } = props

  return (
      <DashboardNavbarRoot {...other}>
        <Toolbar sx={{ height: TOOLBAR_HEIGHT }}>
          <Grid
            container
            sx={{
              alignContent: "center",
            }}
          >
            <Grid
              item
              onClick={customOpenSidebar}
              sx={{
                "&:hover": { cursor: "pointer" },
              }}
            >
              <IconButton color="inherit">
                <MenuIcon fontSize="small" />
              </IconButton>
            </Grid>
            <Grid
              item
              onClick={customOpenSidebar}
              sx={{
                "&:hover": { cursor: "pointer" },
              }}
            >
              <Logo
                sx={{
                  display: {
                    lg: "inline",
                  },
                  height: 40,
                  width: 40,
                  verticalAlign: "center",
                }}
              />
            </Grid>
          </Grid>
          <Box
            sx={{
              flexGrow: 1,
              ml: 2,
            }}
          />
          <Box sx={{ ml: 2 }}>
            <AccountPopover 
              setApiKeyModalOpen={setApiKeyModalOpen} />
          </Box>
        </Toolbar>
      </DashboardNavbarRoot>
  )
}

DashboardNavbar.propTypes = {
  customOpenSidebar: PropTypes.func,
}

export default DashboardNavbar
