import { forwardRef, useRef, useEffect } from "react";
import { Checkbox } from "@material-ui/core";

const IndeterminateCheckbox = forwardRef(
    ({ indeterminate, ...rest }: any, ref: any) => {
        const defaultRef = useRef();
        const resolvedRef = ref || defaultRef;

        useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate;
        }, [resolvedRef, indeterminate]);

        return (
            <>
                <Checkbox ref={resolvedRef} {...rest} />
            </>
        );
    }
);

export default IndeterminateCheckbox;