import { useRef, useState } from "react"
import type { FC } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { InboxLauncher, PresenceFacepile, Sidebar, SidebarLauncher } from "@cord-sdk/react"
import toast from "react-hot-toast"
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Typography,
} from "@material-ui/core"
import KeyIcon from '@mui/icons-material/Key';
import { posthogReset } from "src/analytics"
import useAuth from "../../hooks/useAuth"
import "./style.css"
import "../../pages/dashboard/PolicyCreate.css"

interface AccountPopoverProps  {
  setApiKeyModalOpen: any
}

const AccountPopover: FC<AccountPopoverProps> = (props) => {
  const anchorRef = useRef<HTMLButtonElement | null>(null)

  const { user, logout } = useAuth()
  const navigate = useNavigate()
  const location = useLocation()

  const [open, setOpen] = useState<boolean>(false)


  const handleLogout = async (): Promise<void> => {
    try {
      setOpen(false)
      await logout()
      navigate("/account/logout")
      posthogReset()
    } catch (err) {
      console.error(err)
      toast.error("Unable to logout.")
    }
  }

  return (
    <>
      <Box
        component={ButtonBase}
        ref={anchorRef}
        sx={{
          alignItems: "center",
          display: "flex",
        }}
      >
        {location.pathname === "/dashboard/inventory" && (
          <Box sx={{ mr: 2 }}>
            <SidebarLauncher label="Collaborate" inboxBadgeStyle="badge" />
            <Box className="cord-sidebar-custom">
              <Sidebar showLauncher={false} open={false} />
            </Box>
          </Box>
        )}
        <InboxLauncher inboxBadgeStyle="badge" />

        <Avatar
          src={user.profilePictureUrl}
          sx={{
            height: 32,
            width: 32,
            ml: 2,
          }}
          onClick={() => setOpen(true)}
        />
        <Box ml={2}>
          <PresenceFacepile
            excludeViewer={false}
            maxUsers={7}
            className={"presence_facepile"}
            context={{ page: "audit", component: "audit" }}
          />
        </Box>
      </Box>

      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        keepMounted
        onClose={() => setOpen(false)}
        open={open}
        PaperProps={{
          sx: { width: 240 },
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography color="textPrimary" variant="subtitle2">
            {user.name}
          </Typography>
        </Box>
        <Divider />
        <Box sx={{ mt: 2 }}>
            <MenuItem onClick={() => props.setApiKeyModalOpen(true)}>
              <ListItemIcon>
                <KeyIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography color="textPrimary" variant="subtitle2">
                    API Keys
                  </Typography>
                }
              />
            </MenuItem>
        </Box>
        <Box sx={{ p: 2 }}>
          <Button color="primary" fullWidth onClick={handleLogout} variant="outlined">
            Logout
          </Button>
        </Box>
      </Popover>
    </>
  )
}

export default AccountPopover
