import axios from "axios"
import {apiConfig} from "../config"
import {getAuth} from "firebase/auth"

// const { baseApiUri } = apiConfig/**/

const baseApiUri = "https://api.formalcloud.net"

// Received
export interface AuthResponse {
    challenge_type: string
    custom_token: string
    qr_code: string
    secret: string
    challenge_id: string
    mfa_factor_id: string
    tenant_id: string
}

// Sent
export interface MfaPayload {
    email?: string
    challenge_id: string
    code: string
    mfa_factor_id?: string
}
class AuthApi {
    async confirmNewMFAFactorAndSignInWithToken({ mfa_factor_id, challenge_id, code }: MfaPayload): Promise<AuthResponse> {
        const token = await getAuth().currentUser.getIdToken()
        const idTokenResult = await getAuth().currentUser.getIdTokenResult()

        const result = await axios.post(
            `${baseApiUri}/consumer/auth/confirm-new-mfa-factor`,
            {
                mfa_factor_id,
                challenge_id,
                code,
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    Organisation: idTokenResult.claims["organisation_id"] as string,
                },
            }
        )

        return Promise.resolve(result.data)
    }

    // When signing in with MFA
    async submitLoginMFACodeAndSignInWithToken({ challenge_id, code }: MfaPayload): Promise<AuthResponse> {
        const token = await getAuth().currentUser.getIdToken()
        const idTokenResult = await getAuth().currentUser.getIdTokenResult()

        const result = await axios.post(
            `${baseApiUri}/consumer/auth/submit-login-mfa-code`,
            {
                challenge_id,
                code,
            },
            {
                headers: { Authorization: `Bearer ${token}`, Organisation: idTokenResult.claims["organisation_id"] as string },
            }
        )

        return Promise.resolve(result.data)
    }
}

export const authApi = new AuthApi()
