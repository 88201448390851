import { FC } from "react"
import {Link as RouterLink, useNavigate} from "react-router-dom";
import useSettings from "../../hooks/useSettings";
import {FormalTableColumnData} from "../../components/common/FormalRoundTableSettings";
import {useMutation, useQuery, useQueryClient} from "react-query";
import useAuth from "../../hooks/useAuth";
import {useTransport} from "@bufbuild/connect-query";
import toast from "react-hot-toast";
import {Box, Button, Card, Container, Grid, IconButton, TableCell, Typography} from "@material-ui/core";
import ArrowRightIcon from "../../icons/ArrowRight";
import PlusIcon from "../../icons/Plus";
import {ActionConfig, FormalRoundTable} from "../../components/common/FormalRoundTable";
import {
    deleteDataDomain,
    getDataDomains
} from "@buf/formal_admin.bufbuild_connect-query/admin/v1/inventory-InventoryService_connectquery";

const DataDiscoveryDomainList: FC = () => {
    const navigate = useNavigate()
    const { settings } = useSettings()

    const columnData = FormalTableColumnData["DataDiscoveryDomain"]

    const queryClient = useQueryClient()

    const { token } = useAuth()
    const transport = useTransport()

    const createdQuery = getDataDomains.createUseQueryOptions({},
        {
        transport: transport,
        callOptions: {
            headers: {
                Authorization: `Bearer ${token}`
            },
        }
    })

    const deleteMutation = useMutation({
        ...deleteDataDomain.useMutation({
            transport: transport,
            callOptions: {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            },
            onError: (error) => {
                console.error(error)
                toast.error(error.message)
            },
        }),
        onSuccess: () => {
            // Invalidate and refetch
            queryClient.invalidateQueries(`domains`)
        }
    })


    const actionConfig: ActionConfig = {
        handleAction: async (selectedRow): Promise<void> => {
            const id = selectedRow.original.id
            await deleteMutation.mutateAsync({ id })
        },
        buttonText: "Delete",
        confirmText: "Delete Data Inventory domain",
        color: "secondary",
        toastMessageSuccess: "Deleted successfully.",
    }

    const { data: domains, isLoading } = useQuery("domains",
        () => createdQuery.queryFn(),
        {
            onSuccess: (data) => {
                const cachedData: any[] = queryClient.getQueryData(["domains"])

                if (!data.domains && cachedData && cachedData.length > 0) {
                    queryClient.setQueryData(["domains"], cachedData)
                }
            },
            onError: () => {
                toast.error("We failed to load your integrations.")
            },
        }
    )

    if (isLoading) {
        return <></>
    }

    const queryConfig = { externalData: domains.domains ?? [], accessor: "domains" }

    const renderFunction = (cell, index: any) => {
        if (cell.column.id === "actions") {
            return (
                <TableCell key={index}>
                    <IconButton component={RouterLink} to={`/dashboard/inventory/domain/${cell.row.original.id}`}>
                        <ArrowRightIcon fontSize="small" />
                    </IconButton>
                </TableCell>
            )
        }  else {
            return (
                <TableCell key={index} {...cell.getCellProps()}>
                    {cell.render("Cell")}
                </TableCell>
            )
        }
    }

    return (

        <Box
            sx={{
                backgroundColor: "background.default",
                height: "100%",
                paddingTop: 4,
            }}
        >
            <Container maxWidth={settings.compact ? "xl" : false} sx={{ height: "100%" }}>
                <Grid container justifyContent="space-between" spacing={3} sx={{ height: "10%" }}>
                    <Grid item>
                        <Typography color="textPrimary" variant="h5">
                            Data Discovery Domains
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Box sx={{ m: -1 }}>
                            <Button
                                color="primary"
                                startIcon={<PlusIcon fontSize="small" />}
                                sx={{ m: 1 }}
                                variant="contained"
                                onClick={() => navigate("/dashboard/inventory/domain/create")}
                            >
                                Create a new domain
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
                <Box sx={{ height: "90%" }}>
                    <Card sx={{ height: "100%" }}>
                        <FormalRoundTable
                            queryConfig={queryConfig}
                            columnData={columnData}
                            renderFunction={renderFunction}
                            actionConfig={actionConfig}
                            searchConfig={{ localSearchProperties: ["name"] }}
                        />
                    </Card>
                </Box>
            </Container>
        </Box>

    )
}

export default DataDiscoveryDomainList
