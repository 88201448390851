import { FC } from "react"
import useSettings from "../../hooks/useSettings";
import {Box, Breadcrumbs, Container, Grid, Link, Typography} from "@material-ui/core";
import ChevronRightIcon from "../../icons/ChevronRight";
import {Link as RouterLink} from "react-router-dom";
import CreateDomainModal from "../../components/dashboard/datadiscovery/CreateDomainModal";

const DataDiscoveryDomainCreate: FC = () => {
    const { settings } = useSettings();

    return (
        <>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    py: 8
                }}
            >
                <Container maxWidth={settings.compact ? 'xl' : false}>
                    <Grid
                        container
                        justifyContent="space-between"
                        spacing={3}
                    >
                        <Grid item>
                            <Typography
                                color="textPrimary"
                                variant="h5"
                            >
                                Create a new domain
                            </Typography>
                            <Breadcrumbs
                                aria-label="breadcrumb"
                                separator={<ChevronRightIcon fontSize="small" />}
                                sx={{ mt: 1 }}
                            >
                                <Typography
                                    color="textSecondary"
                                    variant="subtitle2"
                                >
                                    Data Discovery
                                </Typography>
                                <Link
                                    color="textPrimary"
                                    component={RouterLink}
                                    to="/dashboard/inventory/domain"
                                    variant="subtitle2"
                                >
                                    Domains
                                </Link>
                                <Typography
                                    color="textSecondary"
                                    variant="subtitle2"
                                >
                                    Create
                                </Typography>
                            </Breadcrumbs>
                        </Grid>
                    </Grid>
                    <Box sx={{ mt: 3 }}>
                        <CreateDomainModal />
                    </Box>
                </Container>
            </Box>
        </>
    );
}

export default DataDiscoveryDomainCreate
