import useAuth from "./hooks/useAuth";
import { cordApi } from './api/cordApi';
import { CordProvider } from "@cord-sdk/react";
import type { FC } from "react";
import {useQuery} from 'react-query';

interface Props {
  client: any;
  children: any;
}

const DataProvider: FC<Props> = ({children}) : JSX.Element => {
  const { user, isAuthenticated } = useAuth();

  // const { token } = useAuth()
  // const transport = useTransport()

  const { data: clientAuthToken } = useQuery('cord',  async () => await cordApi.getClientAuthToken());
  // const { data } = useQuery({
  //   ...getClientAuthToken.useQuery({}, {
  //     transport: transport,
  //     callOptions: {
  //       headers: {
  //         Authorization: `Bearer ${token}`
  //       },
  //     }
  //   })
  // })

  if (isAuthenticated && user) {
    return (
      <CordProvider clientAuthToken={clientAuthToken}>
        {children}
      </CordProvider>
      );
  }

  return children
};

export default DataProvider;
