import "react-perfect-scrollbar/dist/css/styles.css"
import "nprogress/nprogress.css"
import { StrictMode } from "react"
import ReactDOM from "react-dom"
import { BrowserRouter } from "react-router-dom"
import Cohere from "cohere-js"
import LocalizationProvider from "@material-ui/lab/LocalizationProvider"
import AdapterDateFns from "@material-ui/lab/AdapterDateFns"
import StyledEngineProvider from "@material-ui/core/StyledEngineProvider"
import { AuthProvider } from './contexts/FirebaseContext';
import { SettingsProvider } from "./contexts/SettingsContext"
import App from "./App"
import { cohereKey, IS_DEVELOPING_TURN_OFF_ANALYTICS } from "src/config"
import { load, posthogInit } from "./analytics"
import { init } from "commandbar"

if (!IS_DEVELOPING_TURN_OFF_ANALYTICS) {
  posthogInit()
  load() // Load segment before cohere

  Cohere.init(cohereKey, { segmentIntegration: true }) // cohere
}
init("8cdec887") // commandbar

declare global {
  interface Window {
    analytics: SegmentAnalytics.AnalyticsJS
  }
}

ReactDOM.render(
  <StrictMode>
    <StyledEngineProvider injectFirst>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
      <AuthProvider>
        <SettingsProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </SettingsProvider>
        </AuthProvider>
      </LocalizationProvider>
    </StyledEngineProvider>
  </StrictMode>,
  document.getElementById("root")
)
