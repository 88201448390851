import React, { FC, useEffect, useState } from "react"
import { Domain } from "@buf/formal_admin.bufbuild_es/admin/v1/types/v1/domain_pb"
import { Box, Button, Card, Grid, IconButton, TextField, Typography } from "@mui/material"
import { Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core"
import DeleteIcon from "@mui/icons-material/Delete"
import { User } from "@buf/formal_admin.bufbuild_es/admin/v1/types/v1/user_pb"
import { Group } from "@buf/formal_admin.bufbuild_es/admin/v1/types/v1/group_pb"
import { QueryClient, useMutation, useQuery } from "react-query"
import useAuth from "../../../hooks/useAuth"
import { useTransport } from "@bufbuild/connect-query"
import Select from "react-select"
import toast from "react-hot-toast"
import {
  deleteDataDomain,
  updateDataDomain,
} from "@buf/formal_admin.bufbuild_connect-query/admin/v1/inventory-InventoryService_connectquery"
import { useNavigate } from "react-router"
import {listUsers} from "@buf/formal_admin.bufbuild_connect-query/admin/v1/identities-UserService_connectquery";
import {listGroups} from "@buf/formal_admin.bufbuild_connect-query/admin/v1/identities-GroupService_connectquery";

interface DomainSummaryProps {
  domain: Domain
}

const DomainSummary: FC<DomainSummaryProps> = ({ domain }) => {
  const [users, setUsers] = useState<User[]>([])
  const [groups, setGroups] = useState<Group[]>([])
  const [inputIncluded, setInputIncluded] = useState("")
  const [inputExcluded, setInputExcluded] = useState("")
  const [domainName, setDomainName] = useState<string>("")
  const [description, setDescription] = useState<string>("")
  const [selectedOptions, setSelectedOptions] = useState<any[]>([])
  const [listIncluded, setListIncluded] = useState<string[]>([])
  const [listExcluded, setListExcluded] = useState<string[]>([])

  const { token } = useAuth()
  const transport = useTransport()
  const queryClient = new QueryClient()
  const navigate = useNavigate()

  useEffect(() => {
    if (domain) {
      setDomainName(domain.name)
      setDescription(domain.description)
      setSelectedOptions(
        users.length > 0 && groups.length > 0
          ? domain.owners.map((item) => {
              if (item.objectType == "user") {
                const user = users.find((user) => user.id == item.objectId)
                return {
                  label: user?.name ? user?.name : user.firstName + " " + user.lastName,
                  value: user.id,
                  objectType: "user",
                }
              } else if (item.objectType == "group") {
                const group = groups.find((group) => group.id == item.objectId)
                return { label: group.name, value: group.id, objectType: "group" }
              }
            })
          : []
      )
      setListIncluded(domain.includedPaths)
      setListExcluded(domain.excludedPaths)
    }
  }, [domain, users, groups])

  const updateDomainMutation = useMutation({
    ...updateDataDomain.useMutation({
      transport: transport,
      callOptions: {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
      onError: (error) => {
        console.error(error)
        toast.error(error.message)
      },
    }),
    // onSuccess: async () => {
    //     await queryClient.invalidateQueries("domains")
    //     navigate("/dashboard/discovery/domain")
    // }
  })

  const deleteMutation = useMutation({
    ...deleteDataDomain.useMutation({
      transport: transport,
      callOptions: {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
      onError: (error) => {
        console.error(error)
        toast.error(error.message)
      },
    }),
    onSuccess: async () => {
      await queryClient.invalidateQueries("domains")
      navigate("/dashboard/inventory/domain")
    },
  })

  const getUsersQuery = listUsers.createUseQueryOptions(
    {},
    {
      transport: transport,
      callOptions: {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    }
  )
  const getGroupsQuery = listGroups.createUseQueryOptions(
    { limit: 1000 },
    {
      transport: transport,
      callOptions: {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    }
  )

  useQuery(
    "users",
    async () => {
      const data = await getUsersQuery.queryFn()
      return data.users
    },
    {
      onSuccess: (data) => {
        setUsers(data)
      },
    }
  )
  useQuery(
    "groups",
    async () => {
      const data = await getGroupsQuery.queryFn()
      return data.groups
    },
    {
      onSuccess: (data) => {
        setGroups(data)
      },
    }
  )

  const userList = users.map((user) => ({
    label: user.name ? user.name : user.firstName + " " + user.lastName,
    value: user.id,
    objectType: "user",
  }))
  const groupList = groups.map((group) => ({ label: group.name, value: group.id, objectType: "group" }))

  const groupedOptions = [
    { label: "Users", options: userList },
    { label: "Groups", options: groupList },
  ]

  const handleUpdate = async () => {
    const owners = selectedOptions.map((item) => ({ objectId: item.value, objectType: item.objectType }))
    updateDomainMutation.mutate({
      id: domain.id,
      name: domainName,
      description: description,
      includedPaths: listIncluded,
      excludedPaths: listExcluded,
      owners: owners,
    })
  }

  return (
    <Box sx={{ p: 3 }}>
      <Grid container spacing={3}>
        <Grid item md={12} xs={12}>
          <Card>
            <Box sx={{ p: 3 }}>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    label="Domain Name"
                    name="domain_name"
                    sx={{ mt: 3 }}
                    FormHelperTextProps={{
                      sx: {
                        textAlign: "right",
                        mr: 0,
                      },
                    }}
                    fullWidth
                    rows={5}
                    onChange={(e) => setDomainName(e.target.value)}
                    value={domainName}
                    variant="outlined"
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <TextField
                    label="Description"
                    name="description"
                    sx={{ mt: 3 }}
                    FormHelperTextProps={{
                      sx: {
                        textAlign: "right",
                        mr: 0,
                      },
                    }}
                    fullWidth
                    rows={5}
                    onChange={(e) => setDescription(e.target.value)}
                    value={description}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Select
                    isMulti
                    options={groupedOptions}
                    value={selectedOptions}
                    onChange={setSelectedOptions}
                    styles={{
                      container: (base) => ({
                        ...base,
                        minWidth: "900px",
                      }),
                      option: (base, state) => ({
                        ...base,
                        backgroundColor: state.isFocused ? "#2684FF" : "#171c24",
                      }),
                      menuPortal: (base) => ({ ...base, zIndex: 9999, backgroundColor: "#171c24" }),
                      control: (base) => ({
                        ...base,
                        backgroundColor: "#212B36",
                        border: "1px solid #FFFFFF",
                      }),
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 999999999,
                        backgroundColor: "#171c24",
                        minWidth: "400px",
                      }),
                      multiValue: (provided) => {
                        return { ...provided, backgroundColor: "#688EFF", zIndex: 999 }
                      },
                      indicatorSeparator: (provided) => {
                        return { ...provided, display: "none" }
                      },
                      input: (base) => ({
                        ...base,
                        color: "white",
                      }),
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={12}></Grid>
              </Grid>
            </Box>
          </Card>
        </Grid>
        <Grid item md={6} xs={12}>
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Typography align="center" color="textPrimary" gutterBottom variant="h6">
              Included paths
            </Typography>

            <Box display="flex" justifyContent="space-between" mb={2} width="100%">
              <TextField
                variant="outlined"
                placeholder="Enter path..."
                value={inputIncluded}
                onChange={(e) => setInputIncluded(e.target.value)}
                fullWidth
              />

              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  if (inputIncluded !== "") {
                    setListIncluded((oldList) => [...oldList, inputIncluded])
                    setInputIncluded("")
                  }
                }}
                style={{ marginLeft: "10px" }}
              >
                Add
              </Button>
            </Box>

            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Path</TableCell>
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {listIncluded.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{item}</TableCell>
                      <TableCell align="right">
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => setListIncluded((oldList) => oldList.filter((item, indx) => indx !== index))}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>

        <Grid item md={6} xs={12}>
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Typography align="center" color="textPrimary" gutterBottom variant="h6">
              Excluded paths
            </Typography>

            <Box display="flex" justifyContent="space-between" mb={2} width="100%">
              <TextField
                variant="outlined"
                placeholder="Enter path..."
                value={inputExcluded}
                onChange={(e) => setInputExcluded(e.target.value)}
                fullWidth
              />

              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  if (inputExcluded !== "") {
                    setListExcluded((oldList) => [...oldList, inputExcluded])
                    setInputExcluded("")
                  }
                }}
                style={{ marginLeft: "10px" }}
              >
                Add
              </Button>
            </Box>

            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Path</TableCell>
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {listExcluded.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{item}</TableCell>
                      <TableCell align="right">
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => setListExcluded((oldList) => oldList.filter((item, indx) => indx !== index))}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
        <Grid item md={12} xs={12}>
          <Box display="flex" justifyContent="flex-end" mt={3}>
            <Button variant="contained" color="primary" onClick={handleUpdate}>
              Update
            </Button>
            <Button
              variant="contained"
              color="secondary"
              sx={{ ml: 2 }}
              onClick={() => {
                deleteMutation.mutateAsync({ id: domain.id })
              }}
            >
              Delete
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default DomainSummary
