import {FC, useState} from "react"
import { useNavigate } from "react-router-dom"
import { Box, Button, Card, Container, Grid, TableCell, Typography } from "@material-ui/core"
import useSettings from "../../hooks/useSettings"
import PlusIcon from "../../icons/Plus"
import { useMutation, useQuery, useQueryClient } from "react-query"
import useAuth from "../../hooks/useAuth"
import toast from "react-hot-toast"
import { ActionConfig, FormalRoundTable } from "src/components/common/FormalRoundTable"
import { FormalTableColumnData } from "src/components/common/FormalRoundTableSettings"
import {useTransport} from "@bufbuild/connect-query";
import {
    deleteSatellite, getSatellites
} from "@buf/formal_admin.bufbuild_connect-query/admin/v1/satellite-SatelliteService_connectquery";
import CreateSatelliteModal from "../../components/dashboard/satellite/CreateSatelliteModal";

const SatelliteList: FC = () => {
    const navigate = useNavigate()
    const { settings } = useSettings()
    const [isModalOpen, setModalOpen] = useState<boolean>(false)


    const queryClient = useQueryClient()

    const columnData = FormalTableColumnData["Satellite"]

    const {token} = useAuth()
    const transport = useTransport()


    const getSatellitesQueryConfig = getSatellites.createUseQueryOptions({}, {
        transport: transport,
        callOptions: {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
    })

    const { data: satellites, isLoading, refetch } = useQuery(
        'satellites',
        () => getSatellitesQueryConfig.queryFn(),
        {
            refetchInterval: 10000,
            staleTime: 10000,
            onSuccess: (data) => {
                const cachedData: any[] = queryClient.getQueryData(["satellites"])

                if (!data && cachedData && cachedData.length > 0) {
                    queryClient.setQueryData(["satellites"], cachedData)
                }
            },
            onError: () => {
                toast.error("We failed to load the satellites.")
            },
        }
    )

    // Delete
    const deleteMutation = useMutation({
        ...deleteSatellite.useMutation({
            transport: transport,
            callOptions: {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            },
            onError: (error) => {
                console.error(error)
                toast.error(error.message)
                refetch()
            },
        }),
        onSuccess: () => {
            // Invalidate and refetch
            queryClient.invalidateQueries(`satellites`)
        }
    })

    if (isLoading) {
        return <></>
    }

    const queryConfig = { externalData: satellites.satellites ?? [], accessor: "satellites" }


    const actionConfig: ActionConfig = {
        handleAction: async (selectedRow): Promise<void> => {
            const id = selectedRow.original.id
            await deleteMutation.mutateAsync({ id })
        },
        buttonText: "Delete",
        confirmText: "Delete Satellite",
        color: "secondary",
        toastMessageSuccess: "Deleted successfully.",
    }

    const renderFunction = (cell, index: any) => {
            return (
                <TableCell key={index} {...cell.getCellProps()}>
                    {cell.render("Cell")}
                </TableCell>
            )
    }

    return (
        <>
            <Box
                sx={{
                    backgroundColor: "background.default",
                    height: "100%",
                    py: 4,
                }}
            >
                <Container maxWidth={settings.compact ? "xl" : false} sx={{ height: "100%" }}>
                    <Grid container justifyContent="space-between" spacing={3} sx={{ height: "10%" }}>
                        <Grid item>
                            <Typography color="textPrimary" variant="h5">
                                Satellites
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Box sx={{ m: -1 }}>
                                <Button
                                    color="primary"
                                    startIcon={<PlusIcon fontSize="small" />}
                                    sx={{ m: 1 }}
                                    variant="contained"
                                    onClick={() => setModalOpen(true)}
                                >
                                    New Satellite
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                    <Box sx={{ height: "90%" }}>
                        <Card sx={{ height: "100%" }}>
                            <FormalRoundTable
                                queryConfig={queryConfig}
                                columnData={columnData}
                                renderFunction={renderFunction}
                                searchConfig={{ localSearchProperties: ["name"] }}
                                actionConfig={actionConfig}
                            />
                        </Card>
                    </Box>
                </Container>
            </Box>
            <CreateSatelliteModal open={isModalOpen} setModalOpen={setModalOpen} onClose={() => setModalOpen(false)} />

        </>
    )
}

export default SatelliteList
