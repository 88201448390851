/// <reference types="@types/segment-analytics" />

import posthog from "posthog-js"
import { IS_DEVELOPING_TURN_OFF_ANALYTICS, segmentwriteKey } from "./config"

declare global {
  interface Window {
    analytics: SegmentAnalytics.AnalyticsJS
  }
}

interface SegmentTraits {
  [fieldName: string]: any
}

export const load = (): void => {
  if (!IS_DEVELOPING_TURN_OFF_ANALYTICS) {
    window.analytics?.load(segmentwriteKey)
  }
}
export const page = (): void => {
  if (!IS_DEVELOPING_TURN_OFF_ANALYTICS) {
    window.analytics?.page()
  }
}
export const trackEvent = (name: string, properties: SegmentTraits): void => {
  if (!IS_DEVELOPING_TURN_OFF_ANALYTICS) {
    window.analytics?.track(name, properties)
  }
}

export const identify = (userId: string, traits: SegmentTraits): void => {
  if (!IS_DEVELOPING_TURN_OFF_ANALYTICS) {
    window.analytics?.identify(userId, traits)
  }
}
export const group = (groupId: string, traits: SegmentTraits): void => {
  if (!IS_DEVELOPING_TURN_OFF_ANALYTICS) {
    window.analytics?.group(groupId, traits)
  }
}

// Posthog

export const posthogInit = (): void => {
  if (!IS_DEVELOPING_TURN_OFF_ANALYTICS) {
    posthog.init("phc_CjMpi5g8KGF90r7Zhikpy6YfFfMcEEihXwgMkoPDEYW", {
      api_host: "https://app.posthog.com",
    })
  }
}

export const posthogIdentify = (id: string, traits: posthog.Properties): void => {
  if (!IS_DEVELOPING_TURN_OFF_ANALYTICS) {
    posthog.identify(id, traits)
  }
}

export const posthogReset = (): void => {
  if (!IS_DEVELOPING_TURN_OFF_ANALYTICS) {
    posthog.reset()
  }
}

export const posthogPeopleSet = (traits: posthog.Properties): void => {
  if (!IS_DEVELOPING_TURN_OFF_ANALYTICS) {
    posthog.people.set(traits)
  }
}
