import { useEffect } from 'react';
import type { FC } from 'react';
import { useRoutes, useNavigate } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { TransportProvider } from "@bufbuild/connect-query";
import { QueryClient, QueryClientProvider } from 'react-query';
import { createConnectTransport } from "@bufbuild/connect-web";
import SplashScreen from './components/SplashScreen';
import useScrollReset from './hooks/useScrollReset';
import useSettings from './hooks/useSettings';
import useAuth from './hooks/useAuth';
import routes from './routes';
import { createCustomTheme } from './theme';
import DataProvider from './DataProvider';
import './i18n';
import './index.css';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/cobalt.css';

const queryClient = new QueryClient();

const App: FC = () => {
  const content = useRoutes(routes);
  const { settings } = useSettings();
  const navigate = useNavigate();
  const auth = useAuth();

  useEffect(() => {
    const routerFunc = newUrl => navigate(newUrl);
    window.CommandBar.addRouter(routerFunc);
  }, [])

  const transport = createConnectTransport({
    // baseUrl: "http://localhost:443",
    baseUrl: "https://adminv2.api.formalcloud.net",
  })

  useScrollReset();

  const theme = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme
  });

  return (
      <QueryClientProvider client={queryClient}>
        <TransportProvider transport={transport}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Toaster position="top-center" />
            <DataProvider client={queryClient}>
              {auth.isInitialized ? content : <SplashScreen />}
            </DataProvider>
          </ThemeProvider>
        </TransportProvider>
      </QueryClientProvider>
  );
};

export default App;
