import { useState } from 'react';
import type { FC } from 'react';
import { useQueryClient, useMutation } from "react-query"
import {
  InputAdornment,
  Tooltip,
  IconButton,
  Dialog,
  Box,
  Typography,
  TableCell,
  Button,
  TextField } from '@material-ui/core'
import { useQuery } from 'react-query';
import toast from "react-hot-toast"
import moment from 'moment';
import {
  getApiKeys, createApiKey, deleteApiKey
} from "@buf/formal_admin.bufbuild_connect-query/admin/v1/admin-DevService_connectquery";
import { useTransport } from "@bufbuild/connect-query";
import useAuth from "../../hooks/useAuth"
import { CopyToClipboard } from "react-copy-to-clipboard";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { ActionConfig, FormalRoundTable } from '../common/FormalRoundTable';
import { FormalTableColumnData } from "src/components/common/FormalRoundTableSettings"
import { LoadingButton } from '@material-ui/lab';
import {ConnectError} from "@bufbuild/connect-web";

interface ApiKeyModalProps  {
  openModal: boolean,
  handleClose: () => void
}

const ApiKeyModal: FC<ApiKeyModalProps> = (props: any) => {
  const queryClient = useQueryClient()
  const [isCreationModalOpen, setCreationModalOpen] = useState(false)
  const [name, setName] = useState("")
  const [showSuccess, setShowSuccess] = useState(false)
  const [copied, setCopied] = useState(false)
  const [secret, setSecret] = useState("")
  const [createButtonLoading, setCreateButtonLoading] = useState(false)

  const { token } = useAuth()
  const transport = useTransport()

  const { data: apiKeys } = useQuery({
    ...getApiKeys.useQuery({limit: 1000}, {
        transport: transport,
        callOptions: {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
    })
  })

  const createApiKeyMutation = useMutation({
    onMutate: () => {
      setCreateButtonLoading(true)
    },
    onSuccess: async (res) => {
      await queryClient.invalidateQueries(getApiKeys.getQueryKey({}))
      toast.success("API key created.")
      setSecret(res.secret)
      setShowSuccess(true)
      setName("")
    },
    onSettled: () => {
      setCreateButtonLoading(false)
    },
    ...createApiKey.useMutation({
      transport,
      callOptions: {
        headers: {
          Authorization: `Bearer ${token}`
        }
      },
      onError: (err) => {
        console.error(err)
        toast.error(err.message)
      },
    }),
  })

  const deleteMutation = useMutation({
    onSuccess: async () => {
      await queryClient.invalidateQueries(getApiKeys.getQueryKey({}))
    },
    ...deleteApiKey.useMutation({
      transport,
      callOptions: {
        headers: {
          Authorization: `Bearer ${token}`
        }
      },
      onError: (err) => {
        console.error(err)
        toast.error(err.message)
      },
    }),
  })

  const actionConfig: ActionConfig = {
    handleAction: async (selectedRow): Promise<void> => {
      await deleteMutation.mutateAsync({ id: selectedRow.original.id })
    },
    buttonText: "Delete",
    confirmText: "deletion of API key. It cannot be recovered. Are you sure you want to delete this API key?",
    color: "secondary",
    toastMessageSuccess: "API Key deleted.",
  }

  return (
    <>
      <Dialog
        sx={{ zIndex: 1500 }}
        hideBackdrop
        disablePortal
            fullWidth
            maxWidth="sm"
            disableEnforceFocus
            disableAutoFocus
            open={isCreationModalOpen}
            onClose={() => setCreationModalOpen(false)} >
             {showSuccess ? (
              <Box sx={{ m: 3 }}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  API Key Created
                </Typography>
                <Box sx={{ mt: 3 }}>
                  <Typography variant="body1" component="p">
                    Your API key has been created. Please copy it now. You will not be able to see it again.
                  </Typography>
                </Box>
                <TextField
              fullWidth
              label="API Key"
              name="secret"
              sx={{
                mt: 3,
              }}
              type={'text'}
              value={secret}
              variant="outlined"
              disabled
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <CopyToClipboard text={secret}
                      onCopy={() => setCopied(true)}>
                      <Tooltip title={
                        copied === true
                          ? "This was Copied!"
                          : "Copy To Clipboard"
                      }
                        placement="right">
                        <IconButton>
                          <FileCopyIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </CopyToClipboard>
                  </InputAdornment>
                )
              }}
            />
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    mt: 3,
                  }}
                >
                  <Button onClick={() => {
                    setName("")
                    setCreationModalOpen(false)
                  }}>
                    Close
                  </Button>
                </Box>
              </Box>
             ) : (
              <Box sx={{ m: 3 }}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Create API Key
                </Typography>
                <Box sx={{ mt: 3 }}>
                  <TextField
                    autoFocus
                    fullWidth
                    label="Name"
                    name="name"
                    onChange={(event) => setName(event.target.value)}
                    value={name}
                    variant="outlined"
                    type="text"
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    mt: 3,
                  }}
                >
                  <Button onClick={() => {
                    setName("")
                    setCreationModalOpen(false)
                  }}>
                    Cancel
                  </Button>
                  <LoadingButton
                    sx={{ ml: 1 }}
                    variant="contained"
                    color="primary"
                    loading={createButtonLoading}
                    onClick={async () => await createApiKeyMutation.mutateAsync({ name })}
                  >
                    Create
                  </LoadingButton>
                </Box>
              </Box>
            )}
          </Dialog>
      <Dialog
        fullWidth
        maxWidth="lg"
        disableEnforceFocus
        disableAutoFocus
        open={props.openModal}
        onClose={props.handleClose}>
        <Box sx={{ p: 3 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 3,
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              API Keys
            </Typography>
            <Button variant="contained" onClick={() => setCreationModalOpen(true)}>
              Create API Token
            </Button>
          </Box>
          <FormalRoundTable
            queryConfig={{ externalData: apiKeys?.apiKeys ?? [],  accessor: getApiKeys.getQueryKey({}) }}
            columnData={FormalTableColumnData["APIKeys"]}
            renderFunction={renderFunction}
            searchConfig={{ disabled: true }}
            actionConfig={actionConfig}
          />
        </Box>
      </Dialog>
    </>
  );
};

export default ApiKeyModal;



const renderFunction = (cell: any, index: any) => {
  switch (cell.column.id) {
    case "id":
      return <TableCell key={index} padding="checkbox"></TableCell>
    case "createdAt":
      return (
        <TableCell {...cell.getCellProps()}>
          {moment(cell.value.toDate()).format("LLL")}
        </TableCell>
      )
    default:
      return <TableCell {...cell.getCellProps()}>{cell.render("Cell")}</TableCell>
  }
}
