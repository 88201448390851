import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Typography
} from '@mui/material';
import {
  LoginAmplify
} from '../../components/authentication/login';
import Logo from '../../components/Logo';

const Login: FC = () => {
  return (
    <Box
      sx={{
        backgroundColor: '#222b36',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh'
      }}
    >
      <Container
        maxWidth="sm"
        sx={{ py: '80px' }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mb: 8
          }}
        >
          <RouterLink to="/">
            <Logo
              sx={{
                height: 40,
                width: 40
              }}
            />
          </RouterLink>
        </Box>
        <Card>
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              p: 4
            }}
          >
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                mb: 3
              }}
            >
              <div>
                <Typography
                  color="textPrimary"
                  gutterBottom
                  variant="h4"
                >
                  Log in
                </Typography>
              </div>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                mt: 3
              }}
            >
              <LoginAmplify />
            </Box>
            <Divider sx={{ my: 3 }} />
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};

export default Login;
