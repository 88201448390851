import { Suspense, lazy } from "react"
import type { RouteObject } from "react-router"
import { Navigate } from "react-router-dom"
import AuthGuard from "./components/AuthGuard"
import DashboardLayout from "./components/dashboard/DashboardLayout"
import LoadingScreen from "./components/LoadingScreen"
import MainLayout from "./components/MainLayout"
import DataDiscoveryDomainList from "./pages/dashboard/DataDiscoveryDomainList"
import DataDiscoveryDomainDetails from "./pages/dashboard/DataDiscoveryDomainDetails"
import DataDiscoveryDomainCreate from "./pages/dashboard/DataDiscoveryDomainCreate"
import SatelliteList from "./pages/dashboard/SatelliteList"

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  )

// Dashboard pages
const SidecarList = Loadable(lazy(() => import("./pages/dashboard/SidecarList")))
const SidecarDetails = Loadable(lazy(() => import("./pages/dashboard/SidecarDetails")))
const DataStoreCreate = Loadable(lazy(() => import("./pages/dashboard/DataStoreCreate")))
const DatastoreV2List = Loadable(lazy(() => import("./pages/dashboard/DatastoreV2List")))
const DatastoreV2Details = Loadable(lazy(() => import("./pages/dashboard/DatastoreV2Details")))

//
const DataInventoryList = Loadable(lazy(() => import("./pages/dashboard/DataInventoryList")))
const DataInventoryMetrics = Loadable(lazy(() => import("./pages/dashboard/DataInventoryMetrics")))
const DataInventoryTags = Loadable(lazy(() => import("./pages/dashboard/DataInventoryTags")))

//
const AuditList = Loadable(lazy(() => import("./pages/dashboard/AuditList")))
const AuditDetails = Loadable(lazy(() => import("./pages/dashboard/AuditDetails")))
const PolicyDetails = Loadable(lazy(() => import("./pages/dashboard/PolicyDetails")))
const PolicyList = Loadable(lazy(() => import("./pages/dashboard/PolicyList")))
const PolicyCreate = Loadable(lazy(() => import("./pages/dashboard/PolicyCreate")))
const PolicyTemplates = Loadable(lazy(() => import("./pages/dashboard/PolicyTemplates")))

const IdentitiesGroupsDetails = Loadable(lazy(() => import("./pages/dashboard/IdentitiesGroupsDetails")))
const IdentitiesGroupsList = Loadable(lazy(() => import("./pages/dashboard/IdentitiesGroupsList")))
const IdentitiesUsersList = Loadable(lazy(() => import("./pages/dashboard/IdentitiesUsersList")))
const IdentitiesUsersDetails = Loadable(lazy(() => import("./pages/dashboard/IdentitiesUsersDetails")))

const IntegrationDirectorySync = Loadable(lazy(() => import("./pages/dashboard/DirectorySyncPage")))
const IntegrationListCloudProviders = Loadable(lazy(() => import("./pages/dashboard/IntegrationCloudAccountsList")))
const IntegrationCloudDetails = Loadable(lazy(() => import("./pages/dashboard/IntegrationCloudDetails")))
const IntegrationSSOPage = Loadable(lazy(() => import("./pages/dashboard/IntegrationSSOPage")))
const IntegrationCreateCloudProvider = Loadable(lazy(() => import("./pages/dashboard/IntegrationCloudAccountsCreate")))
const IntegrationEncryptionCreate = Loadable(lazy(() => import("./pages/dashboard/IntegrationEncryptionKeysCreate")))
const IntegrationEncryptionList = Loadable(lazy(() => import("./pages/dashboard/IntegrationEncryptionKeys")))
const IntegrationSlack = Loadable(lazy(() => import("./pages/dashboard/IntegrationChat")))
const IntegrationGithub = Loadable(lazy(() => import("./pages/dashboard/IntegrationGithub")))
const AllOutputs = Loadable(lazy(() => import("./pages/dashboard/AllOutputs")))
const IntegrationAppsPage = Loadable(lazy(() => import("./pages/dashboard/IntegrationAppsPage")))
const IntegrationAppsCreatePage = Loadable(lazy(() => import("./pages/dashboard/IntegrationAppsCreatePage")))
const IntegrationSSOCreatePage = Loadable(lazy(() => import("./pages/dashboard/IntegrationSSOCreatePage")))
const IntegrationLogsCreatePage = Loadable(lazy(() => import("./pages/dashboard/IntegrationLogsCreatePage")))
const IntegrationLogsPage = Loadable(lazy(() => import("./pages/dashboard/IntegrationLogsPage")))
const IntegrationExternalAPICreatePage = Loadable(
  lazy(() => import("./pages/dashboard/IntegrationExternalAPICreatePage"))
)
const IntegrationExternalAPIPage = Loadable(lazy(() => import("./pages/dashboard/IntegrationExternalAPIPage")))
const IntegrationDatahubPage = Loadable(lazy(() => import("./pages/dashboard/IntegrationDatahubPage")))

const CustomMetrics = Loadable(lazy(() => import("./pages/dashboard/AnalyticsCustomMetrics")))
const AnalyticsCustomMetricsDetails = Loadable(lazy(() => import("./pages/dashboard/AnalyticsCustomMetricsDetails")))

// Error pages
const AuthorizationRequired = Loadable(lazy(() => import("./pages/AuthorizationRequired")))

// Authentication pages
const Login = Loadable(lazy(() => import("./pages/authentication/Login")))
const VerifyCode = Loadable(lazy(() => import("./pages/authentication/VerifyCode")))
const SetupMFA = Loadable(lazy(() => import("./pages/authentication/SetupMFA")))
const VerifyMFA = Loadable(lazy(() => import("./pages/authentication/VerifyMFA")))

const routes: RouteObject[] = [
  {
    caseSensitive: false,
    path: "authentication",
    children: [
      {
        caseSensitive: false,
        path: "login",
        element: <Login />,
      },
      {
        caseSensitive: false,
        path: "verify-code",
        element: <VerifyCode />,
      },
      {
        caseSensitive: false,
        path: "setup-mfa",
        element: <SetupMFA />,
      },
      {
        caseSensitive: false,
        path: "verify-mfa",
        element: <VerifyMFA />,
      },
    ],
  },
  {
    caseSensitive: false,
    path: "dashboard",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        caseSensitive: false,
        path: "",
        element: <Navigate to="/dashboard/sources" />,
      },
      {
        caseSensitive: false,
        path: "login",
        element: <p />,
      },
      {
        caseSensitive: false,
        path: "sources",
        children: [
          {
            caseSensitive: false,
            path: "",
            element: <SidecarList />,
          },
          {
            caseSensitive: false,
            path: ":sourceId",
            element: <SidecarDetails />,
          },
        ],
      },
      {
        caseSensitive: false,
        path: "datastores",
        children: [
          {
            caseSensitive: false,
            path: "",
            element: <DatastoreV2List />,
          },
          {
            caseSensitive: false,
            path: ":sourceId",
            element: <DatastoreV2Details />,
          },
          {
            caseSensitive: false,
            path: "create",
            element: <DataStoreCreate />,
          },
        ],
      },
      {
        caseSensitive: false,
        path: "inventory",
        children: [
          {
            caseSensitive: false,
            path: "",
            element: <DataInventoryList />,
          },
          {
            caseSensitive: false,
            path: "tags",
            element: <DataInventoryTags />,
          },
          {
            caseSensitive: false,
            path: "domain",
            element: <DataDiscoveryDomainList />,
          },
          {
            caseSensitive: false,
            path: "domain/:domainId",
            element: <DataDiscoveryDomainDetails />,
          },
          {
            caseSensitive: false,
            path: "domain/create",
            element: <DataDiscoveryDomainCreate />,
          },
        ],
      },
      {
        caseSensitive: false,
        path: "discovery",
        children: [
          {
            caseSensitive: false,
            path: "datahub",
            element: <IntegrationDatahubPage />,
          },
        ],
      },
      {
        caseSensitive: false,
        path: "analytics",
        children: [
          {
            caseSensitive: false,
            path: "flows",
            element: <DataInventoryMetrics />,
          },
        ],
      },
      {
        caseSensitive: false,
        path: "metrics",
        children: [
          {
            caseSensitive: false,
            path: "custom",
            children: [
              {
                caseSensitive: false,
                path: "",
                element: <CustomMetrics />,
              },
              {
                caseSensitive: false,
                path: ":metricId",
                element: <AnalyticsCustomMetricsDetails />,
              },
            ],
          },
        ],
      },
      {
        caseSensitive: false,
        path: "policies",
        children: [
          {
            caseSensitive: false,
            path: "",
            element: <PolicyList />,
          },
          {
            caseSensitive: false,
            path: "create",
            element: <PolicyCreate />,
          },
          {
            caseSensitive: false,
            path: "templates",
            element: <PolicyTemplates />,
          },
          {
            caseSensitive: false,
            path: ":policyId",
            children: [
              {
                caseSensitive: false,
                path: "",
                element: <PolicyDetails />,
              },
              {
                caseSensitive: false,
                path: "edit",
                element: <PolicyCreate />,
              },
            ],
          },
        ],
      },
      {
        caseSensitive: false,
        path: "audit",
        children: [
          {
            caseSensitive: false,
            path: "",
            element: <AuditList />,
          },
          {
            caseSensitive: false,
            path: ":logId",
            element: <AuditDetails />,
          },
        ],
      },
      {
        element: <SatelliteList />,
        caseSensitive: false,
        path: "satellites",
      },
      {
        caseSensitive: false,
        path: "identities",
        children: [
          {
            caseSensitive: false,
            path: "users",
            element: <IdentitiesUsersList />,
          },
          {
            caseSensitive: false,
            path: "users/:id",
            element: <IdentitiesUsersDetails />,
          },
          {
            caseSensitive: false,
            path: "groups",
            element: <IdentitiesGroupsList />,
          },
          {
            caseSensitive: false,
            path: "groups/:id",
            element: <IdentitiesGroupsDetails />,
          },
        ],
      },
      {
        caseSensitive: false,
        path: "integrations",
        children: [
          {
            caseSensitive: false,
            path: "cloud-providers",
            children: [
              {
                caseSensitive: false,
                path: "",
                element: <IntegrationListCloudProviders />,
              },
              {
                caseSensitive: false,
                path: "create",
                element: <IntegrationCreateCloudProvider />,
              },
              {
                caseSensitive: false,
                path: ":integrationId",
                element: <IntegrationCloudDetails />,
              },
            ],
          },
          {
            caseSensitive: false,
            path: "sso",
            children: [
              {
                caseSensitive: false,
                path: "",
                element: <IntegrationSSOPage />,
              },
              {
                caseSensitive: false,
                path: "create",
                element: <IntegrationSSOCreatePage />,
              },
            ],
          },
          {
            caseSensitive: false,
            path: "apps",
            children: [
              {
                caseSensitive: false,
                path: "",
                element: <IntegrationAppsPage />,
              },
              {
                caseSensitive: false,
                path: "create",
                element: <IntegrationAppsCreatePage />,
              },
            ],
          },
          {
            caseSensitive: false,
            path: "logs",
            children: [
              {
                caseSensitive: false,
                path: "",
                element: <IntegrationLogsPage />,
              },
              {
                caseSensitive: false,
                path: "create",
                element: <IntegrationLogsCreatePage />,
              },
            ],
          },
          {
            caseSensitive: false,
            path: "encryption",
            children: [
              {
                caseSensitive: false,
                path: "",
                element: <IntegrationEncryptionList />,
              },
              {
                caseSensitive: false,
                path: "create",
                element: <IntegrationEncryptionCreate />,
              },
            ],
          },
          // {
          //   caseSensitive: false,
          //   path: "slack",
          //   children: [
          //     {
          //       caseSensitive: false,
          //       path: "",
          //       element: <IntegrationSlack />,
          //     },
          //   ],
          // },
          {
            caseSensitive: false,
            path: "github",
            children: [
              {
                caseSensitive: false,
                path: "",
                element: <IntegrationGithub />,
              },
            ],
          },
          {
            caseSensitive: false,
            path: "directory-sync",
            children: [
              {
                caseSensitive: false,
                path: "",
                element: <IntegrationDirectorySync />,
              },
            ],
          },
          {
            caseSensitive: false,
            path: "apis",
            children: [
              {
                caseSensitive: false,
                path: "",
                element: <IntegrationExternalAPIPage />,
              },
              {
                caseSensitive: false,
                path: "create",
                element: <IntegrationExternalAPICreatePage />,
              },
            ],
          },
          {
            caseSensitive: false,
            path: "datahub",
            children: [
              {
                caseSensitive: false,
                path: "",
                element: <IntegrationDatahubPage />,
              },
            ],
          },
        ],
      },
      // {
      //   caseSensitive: false,
      //   path: "logs",
      //   element: <AllOutputs />,
      // },
    ],
  },
  {
    caseSensitive: false,
    path: "*",
    element: <MainLayout />,
    children: [
      {
        caseSensitive: false,
        path: "",
        element: <Navigate to="/dashboard/sources" />,
      },
      {
        caseSensitive: false,
        path: "401",
        element: <AuthorizationRequired />,
      },
      {
        caseSensitive: false,
        path: "404",
        element: <Navigate to="/dashboard/sources" />,
      },
      {
        caseSensitive: false,
        path: "*",
        element: <Navigate to="/dashboard/sources" />,
      },
    ],
  },
]

export default routes
