import { FC, useState } from "react"
import { useNavigate } from "react-router-dom"
import * as Yup from "yup"
import isValidDomain from "is-valid-domain"
import axios from "axios"
import { Formik } from "formik"
import toast from "react-hot-toast"
import { Box, Button, FormHelperText, TextField } from "@mui/material"
import { apiConfig } from "../../../config"

const { baseApiUri } = apiConfig

const LoginAmplify: FC = (props) => {
  const navigate = useNavigate()
  const [emailSent, setEmailSent] = useState(false)
  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
        submit: "",
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email("Must be a valid email").max(255).required("Email is required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }): Promise<void> => {
        try {
          const domain = values.email.split("@").pop()
          if (isValidDomain(domain)) {
            const r = await axios.get(`${baseApiUri}/consumer/auth/check-sso-login/${values.email}?app=console`)
            if (r.data.data) {
              // SSO enabled
              window.localStorage.setItem("sso-email", values.email)
              // TODO: just return the below in the check-sso response instead of separate API call
              const res = await axios.get(`${baseApiUri}/consumer/auth/sso-login/${values.email}?app=console`)
              window.location.href = res.data.data
              return
            } else {
              setEmailSent(true)
              toast.success("A sign in link was sent to your email.", {duration: 10000})
            }
          }
        } catch (err) {
          console.error(err)
          if (err.code === "UserNotConfirmedException") {
            navigate("/authentication/verify-code", {
              state: {
                username: values.email,
              },
            })
            return
          }

          toast.error("There was an error authenticating this email. " + err.response.data.message)
          setStatus({ success: false })
          setErrors({ submit: err.response.data.message })
          setSubmitting(false)
        }
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }): JSX.Element => (
        <form noValidate onSubmit={handleSubmit} {...props}>
          <TextField
            autoFocus
            error={Boolean(touched.email && errors.email)}
            fullWidth
            label="Email Address"
            margin="normal"
            name="email"
            onBlur={handleBlur}
            onChange={(e) => {
              handleChange(e)
            }}
            type="email"
            value={values.email}
            variant="outlined"
          />
          {errors.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
          <Box sx={{ mt: 2 }}>
            <Button color="primary" disabled={isSubmitting || emailSent} fullWidth size="large" type="submit" variant="contained">
              {!emailSent ? "Log In" : "Sign in link was sent to email"}
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  )
}

export default LoginAmplify
