import type { FC, ReactNode } from "react"
import { useState, useEffect } from "react"
import { Navigate, useLocation, useNavigate } from "react-router-dom"
import PropTypes from "prop-types"
import queryString from "query-string"
import { getAuth, signInWithCustomToken } from "firebase/auth"
import useAuth from "../hooks/useAuth"
import Loading from "./LoadingScreen"
import { group, identify, posthogIdentify, posthogPeopleSet } from "src/analytics"
import Cohere from "cohere-js"
import Login from "src/pages/authentication/Login"

const MFA_STATE_MFA_SETUP_ONGOING = "MFA_SETUP_ONGOING" // also used as a CHALLENGE_TYPE
const CHALLENGE_TYPE_MFA_CHALLENGE = "MFA_CHALLENGE"

interface AuthGuardProps {
  children: ReactNode
}

const AuthGuard: FC<AuthGuardProps> = (props) => {
  const { children } = props

  const auth = useAuth()
  const location = useLocation()
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)
  const [requestedLocation, setRequestedLocation] = useState(null)

  useEffect(() => {
    const checkQueryParams = async () => {
      // const email = window.localStorage.getItem("sso-email");
      const params: {
        status?: string
        challenge_type?: string
        custom_token?: string
        qr_code?: string
        challenge_id?: string
        mfa_factor_id?: string
        tenant_id?: string
      } = queryString.parse(location.search)

      if (!params.custom_token) {
        console.log("No Query Params")
        return
      }
      console.log("Query Params detected. Signing in...")
      // console.log(params)
      setIsLoading(true)

      const fbAuth = getAuth()
      fbAuth.tenantId = params.tenant_id

      const token = params.custom_token
      await signInWithCustomToken(fbAuth, token)

      const challengeType = params.challenge_type

      // Setup MFA
      if (challengeType === MFA_STATE_MFA_SETUP_ONGOING) {
        // Navigate to setup MFA with this new token
        console.log("Navigating to MFA Setup")
        navigate("/authentication/setup-mfa", {
          state: params,
        })
        return
      }

      // Login MFA
      if (challengeType === CHALLENGE_TYPE_MFA_CHALLENGE) {
        console.log("Navigating to MFA Verify")

        const challenge_id = params.challenge_id
        navigate("/authentication/verify-mfa", {
          state: {
            challenge_id,
          },
        })
        return
      }

      navigate("/dashboard/sources")
      setIsLoading(false)
    }

    checkQueryParams()
  }, [location])

  useEffect(() => {
    // // if (user) {
    // {/*  identify(user.email, {*/}
    // {/*    email: user.email,*/}
    // //     name: user.name,
    // //   })
    //
    //
    //   // OrgID
    //   if (!user.customClaims || !user.customClaims?.org_id) {
    //     setOnboard(true)
    {/*  } else {*/}
    {/*    identify(user.email, {*/}
    //       email: user.email,
    //       name: user.name,
    //       orgId: user.customClaims.org_id,
    //       id: user.id,
    //     })
    //
    //     group(user.customClaims.org_id, {})
    //
    //     posthogIdentify(user.id, { orgId: user.customClaims.org_id })
    //     posthogPeopleSet({ email: user.email })
    //
    //     Cohere.identify(
    //       user.email, // Required: can be any unique ID
    //       {
    //         name: user.name,
    //         email: user.email,
    //         id: user.id,
    //         orgId: user.customClaims.org_id,
    //       }
    //     )
    //     window.CommandBar.boot(user.id)
    //
    //     const resourcesToRetrieve = [
    //       { accessor: "roles", endpoint: identitiesApi.getRoles, key: "roles" },
    //       { accessor: "datastores", endpoint: dataStoreApiV2.getDataStores, key: "datastores" },
    //       { accessor: "sidecars", endpoint: sidecarApi.getSidecars, key: "sidecars" },
    //       { accessor: "groups", endpoint: identitiesApi.getGroups, key: "groups" },
    //       // { accessor: "policies", endpoint: policyApi.getPolicies, key: "policies" },
    //     ]
    //
    //     resourcesToRetrieve.map((resource) => {
    //       resource
    //         .endpoint(user, (100).toString(), "", false)
    //         .then((data) => {
    //           const res = data[resource.accessor]
    //           window.CommandBar.addContext(resource.key, res, {
    //             renderOptions: {
    //               labelKey: "name",
    //             },
    //             quickFindOptions: {
    //               quickFind: true,
    //             },
    //           })
    //         })
    //         .catch((e) => {
    //           console.error(e)
    //         })
    //     })
    //   }
    // }
    const onAuthentication = async () => {
      const { user, isAuthenticated } = auth
      if (user && isAuthenticated) {
        console.log("User is authenticated.")
        posthogIdentify(user.id, {
          firstName: user.firstName,
          lastName: user.lastName,
          name: user.name,
          orgId: user.organisation_id,
        })
        posthogPeopleSet({ email: user.email })
        identify(user.email, {
          email: user.email,
          name: user.name,
        })

        Cohere.identify(
          user.email, // Required: can be any unique ID
          {
            name: user.name,
            email: user.email,
            id: user.id,
            orgId: user.organisation_id,
          }
        )
        window.CommandBar.boot(user.id)

        const idTokenResult = await getAuth().currentUser.getIdTokenResult()
        const mfaChallengeComplete = idTokenResult.claims["mfa_challenge_complete"] as unknown as boolean
        if (
            location.pathname !== "/authentication/verify-mfa" &&
            location.pathname !== "/authentication/setup-mfa" &&
            location.pathname !== "/dashboard/login" &&
            location.pathname !== "/authentication/login" &&
            !mfaChallengeComplete
        ) {
          console.log("Enforce MFA")
          navigate("/authentication/mfa")
        }
      }
    }

    onAuthentication()

  }, [auth])

  if (isLoading) return <Loading />

  if (!auth.isAuthenticated) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname)
    }
    return <Login />
  }

  // if (!user) {
  //   return <Loading />
  // }

  // This is done so that in case the route changes by any chance through other
  // means between the moment of request and the render we navigate to the initially
  // requested route.
  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null)
    return <Navigate to={requestedLocation} />
  }

  return <>{children}</>
}

AuthGuard.propTypes = {
  children: PropTypes.node,
}

export default AuthGuard
