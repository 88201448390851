import { FC, useState } from "react"
import { Drawer } from "@material-ui/core"

interface DashboardSidebarRProps {
  customSidebarIsOpen: boolean
  customCloseSidebar: () => void
  content: any
  side: "left" | "right"
  variant: "persistent" | "temporary"
}

export const DashboardSidebarReusable: FC<DashboardSidebarRProps> = ({
  customSidebarIsOpen,
  customCloseSidebar,
  content,
  side,
  variant,
}) => {
  const [sidebarIsOpen, setSidebarIsOpen] = useState<boolean>(false)

  const onClose = () => {
    setSidebarIsOpen(false)
    if (customCloseSidebar) {
      customCloseSidebar()
    }
  }

  const open = sidebarIsOpen || customSidebarIsOpen

  return (
    <Drawer
      anchor={side}
      onClose={onClose}
      open={open || sidebarIsOpen}
      PaperProps={{
        sx: {
          backgroundColor: "background.paper",
          width: 280,
        },
      }}
      variant={variant}
    >
      {content}
    </Drawer>
  )
}
