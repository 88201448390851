export const THEMES = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
  NATURE: 'NATURE'
};


export const terraformResourceIdDelimiter = "#_#"

export const DATAHUB_ENTITIES = [{
  "value": "data_labels",
  "label": "Data Labels",
},
  {
    "value": "tags",
    "label": "Tags",
  }]
