import { useState, useEffect } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { Box, Dialog, TextField, Typography } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { useQueryClient, useMutation } from 'react-query';
import {createGroup} from "@buf/formal_admin.bufbuild_connect-query/admin/v1/identities-GroupService_connectquery";
import {useTransport} from "@bufbuild/connect-query";
import useAuth from "../../../hooks/useAuth";
import {
    createSatellite
} from "@buf/formal_admin.bufbuild_connect-query/admin/v1/satellite-SatelliteService_connectquery";

interface CreateSatelliteModalProps {
    setModalOpen: (bool) => void;
    onClose?: () => void;
    open: boolean;
}

const CreateSatelliteModal: FC<CreateSatelliteModalProps> = (props) => {
    const {
        setModalOpen,
        onClose,
        open,
        ...other
    } = props;

    const [name, setName] = useState<string>('');
    const [createButtonDisabled, setCreateButtonDisabled] = useState<boolean>(true);
    const [createButtonLoading, setCreateButtonLoading] = useState<boolean>(false);

    const queryClient = useQueryClient();

    const resetState = () => {
        setName('');
    };
    const { token } = useAuth()
    const transport = useTransport()

    const mutation = useMutation({
        ...createSatellite.useMutation({
            transport: transport,
            callOptions: {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            },
            onError: (error) => {
                console.error(error)
                toast.error(error.message)
            },
        }),
        onSuccess: () => {
            queryClient.invalidateQueries('satellites');
            setCreateButtonDisabled(true);
            setModalOpen(false);
            resetState();
            toast.success('Satellite created!');
        },
        onSettled: () => {
            setCreateButtonLoading(false);
        }
    });

    const handleApply = async (): Promise<void> => {
        setCreateButtonLoading(true);
        await mutation.mutateAsync({ name });
    };

    useEffect(() => {
        if (name.length > 0) {
            setCreateButtonDisabled(false);
        }
    }, [name]);

    return (
        <Dialog
            maxWidth="lg"
            onClose={onClose}
            open={open}
            {...other}
        >
            <Box sx={{ p: 3 }}>
                <Typography
                    align="center"
                    color="textPrimary"
                    gutterBottom
                    variant="h4"
                >
                    Create a new satellite
                </Typography>
                <TextField
                    autoFocus
                    sx={{ mt: 3 }}
                    FormHelperTextProps={{
                        sx: {
                            textAlign: 'right',
                            mr: 0
                        }
                    }}
                    fullWidth
                    label="Satellite Name"
                    onChange={(e) => setName(e.target.value)}
                    rows={5}
                    value={name}
                    variant="outlined"
                />
                <Box sx={{ mt: 5 }}>
                    <LoadingButton
                        color="primary"
                        fullWidth
                        onClick={handleApply}
                        variant="contained"
                        loading={createButtonLoading}
                        disabled={createButtonDisabled}
                    >
                        Create
                    </LoadingButton>
                </Box>
            </Box>
        </Dialog>
    );
};

CreateSatelliteModal.propTypes = {
    setModalOpen: PropTypes.func,
    onClose: PropTypes.func,
    open: PropTypes.bool.isRequired
};

export default CreateSatelliteModal;
