import { useState } from "react"
import type { FC } from "react"
import { Box, Button, Dialog, Typography, TextField } from "@material-ui/core"
import { LoadingButton } from "@material-ui/lab"
import { ActionConfig } from "./FormalRoundTable"

interface BulkActionButtonProps {
  actionConfig: ActionConfig
  detailText?: string
  deletionFunction: () => void // this is what is actually run, is supposed to include all p
  setIsDeleting?: (boolean) => void // parent can pass this if the deletion status is needed for logic in the parent component
  disabled?: boolean // parent can pass this as an additional requirement to mark button as disabled
}

const BulkActionButton: FC<BulkActionButtonProps> = (props) => {
  const [isModalOpen, setModalOpen] = useState<boolean>(false)
  const closeModal = () => setModalOpen(false)
  const { actionConfig } = props

  return (
    <Box ml={2}>
      <Button
        color={actionConfig.color}
        variant="contained"
        onClick={() => {
          setModalOpen(true)
        }}
        disabled={props.disabled}
      >
        {actionConfig.buttonText}
      </Button>
      <DeleteItemModal {...props} setModalOpen={setModalOpen} onClose={closeModal} open={isModalOpen} />
    </Box>
  )
}

interface PropsDeleteItemModal extends BulkActionButtonProps {
  setModalOpen: (bool) => void
  onClose?: () => void
  open: boolean
}
const DeleteItemModal: FC<PropsDeleteItemModal> = (props) => {
  const [confirmDeleteInput, setConfirmDeleteInput] = useState<string>("")
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleDelete = async () => {
    setIsLoading(true)
    if (props.setIsDeleting) {
      props.setIsDeleting(true)
    }

    try {
      await props.deletionFunction()
    } catch (e) {
      console.log(e)
    } finally {
      setIsLoading(false)
      if (props.setIsDeleting) {
        props.setIsDeleting(false)
      }
      props.setModalOpen(false)
      setConfirmDeleteInput("")
    }
  }

  return (
    <Dialog maxWidth="sm" fullWidth open={props.open} onClose={props.onClose}>
      <Box sx={{ p: 3 }}>
        <Typography align="center" color="textPrimary" gutterBottom variant="h5">
          Confirm {props.actionConfig.confirmText}
        </Typography>
        {props.actionConfig.subtext !== null && (
        <Typography align="center" color="textPrimary" gutterBottom variant="subtitle1">
          {props.actionConfig.subtext}
        </Typography>)
        }
        {props.detailText !== null && (
          <Typography align="center" color="textPrimary" gutterBottom variant="subtitle1">
            {props.detailText}
          </Typography>
        )}
        <Box sx={{ mt: 5 }}>
          <Typography align="center" color="textPrimary" gutterBottom variant="subtitle1">
            Type <b>confirm</b> below and press Confirm.
          </Typography>
          <TextField
            label="confirm"
            name="key_name"
            sx={{ mt: 3 }}
            FormHelperTextProps={{
              sx: {
                textAlign: "right",
                mr: 0,
              },
            }}
            fullWidth
            rows={5}
            onChange={(e) => {
              setConfirmDeleteInput(e.target.value)
            }}
            value={confirmDeleteInput}
            variant="outlined"
          />
        </Box>
        <Box sx={{ mt: 5 }}>
          <LoadingButton
            color={props.actionConfig.color}
            fullWidth
            onClick={handleDelete}
            variant="contained"
            loading={isLoading}
            disabled={confirmDeleteInput !== "confirm" || props.disabled}
          >
            Confirm
          </LoadingButton>
        </Box>
      </Box>
    </Dialog>
  )
}

export { BulkActionButton }
