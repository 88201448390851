 import { createContext, useEffect, useReducer } from "react"
import type { FC, ReactNode } from "react"
import PropTypes from "prop-types"
import Cohere from "cohere-js"
import { firebaseConfig, IS_DEV_ENV } from "../config"
import type { User } from "../types/identity"
import { authApi } from "src/api/authApi"
import { initializeApp } from "firebase/app"
import { getAuth, signInWithCustomToken, signOut } from "firebase/auth"

interface State {
    isInitialized: boolean
    isAuthenticated: boolean
    user: User | null
    token: string
}

initializeApp(firebaseConfig)

export interface AuthContextValue extends State {
    platform: "Firebase"
    // loginEmailPassword: (tenantId: string, email: string, password: string) => Promise<any>
    logout: () => Promise<void>
    confirmNewMFAFactorAndSignInWithToken: (mfa_factor_id: string, challenge_id: string, code: string) => Promise<void>
    submitLoginMFACodeAndSignInWithToken: (challengeId, code: string) => Promise<void>
    refreshLogin: () => Promise<void>
}

const initialState: AuthContextValue = {
    isAuthenticated: false,
    isInitialized: false,
    user: null,
    platform: "Firebase",
    token: "",
    logout: () => Promise.resolve(),
    confirmNewMFAFactorAndSignInWithToken: () => Promise.resolve(),
    submitLoginMFACodeAndSignInWithToken: () => Promise.resolve(),
    refreshLogin: () => Promise.resolve(),
}

const AuthContext = createContext<AuthContextValue>(initialState)

interface AuthProviderProps {
    children: ReactNode
}

type InitializeAction = {
    type: "INITIALIZE"
    payload: {
        isAuthenticated: boolean
        token: string
        user: User | null
    }
}

type LoginAction = {
    type: "LOGIN"
    payload: {
        user: User
    }
}

type LogoutAction = {
    type: "LOGOUT"
}

type RegisterAction = {
    type: "REGISTER"
}

type VerifyCodeAction = {
    type: "VERIFY_CODE"
}

type ResendCodeAction = {
    type: "RESEND_CODE"
}
type PasswordRecoveryAction = {
    type: "PASSWORD_RECOVERY"
}

type PasswordResetAction = {
    type: "PASSWORD_RESET"
}

type MFASetup = {
    type: "MFA_SETUP"
    payload: {
        cognitoUser: any
    }
}

type Action =
    | InitializeAction
    | LoginAction
    | LogoutAction
    | RegisterAction
    | VerifyCodeAction
    | ResendCodeAction
    | PasswordRecoveryAction
    | PasswordResetAction
    | MFASetup
// | UpdateAction;

const handlers: Record<string, (state: State, action: Action) => State> = {
    INITIALIZE: (state: State, action: InitializeAction): State => {
        const { isAuthenticated, user, token } = action.payload

        return {
            ...state,
            isAuthenticated,
            isInitialized: true,
            user,
            token
        }
    },
    LOGIN: (state: State, action: LoginAction): State => {
        const { user } = action.payload

        if (!IS_DEV_ENV) {
            Cohere.identify(user.id, {
                displayName: user.name,
                email: user.email,
            })
        }

        return {
            ...state,
            isAuthenticated: true,
            user,
        }
    },
    LOGOUT: (state: State): State => ({
        ...state,
        isAuthenticated: false,
        user: null,
    }),
    REGISTER: (state: State): State => ({ ...state }),
    VERIFY_CODE: (state: State): State => ({ ...state }),
    RESEND_CODE: (state: State): State => ({ ...state }),
    PASSWORD_RECOVERY: (state: State): State => ({ ...state }),
    PASSWORD_RESET: (state: State): State => ({ ...state }),
    VERIFY_MFA: (state: State): State => ({ ...state }),
    MFA_SETUP: (state: State): State => ({ ...state }),
}

const reducer = (state: State, action: Action): State =>
    handlers[action.type] ? handlers[action.type](state, action) : state

export const AuthProvider: FC<AuthProviderProps> = (props) => {
    const { children } = props
    const [state, dispatch] = useReducer(reducer, initialState)

    // Initial value
    useEffect(() => {
        const unsubscribe = getAuth().onAuthStateChanged(async (currentUser) => {
            if (currentUser) {
                const idTokenResult = await currentUser.getIdTokenResult()
                const org_id = idTokenResult.claims["organisation_id"]
                const token = await getAuth().currentUser.getIdToken()

                dispatch({
                    type: "INITIALIZE",
                    payload: {
                        isAuthenticated: true,
                        token: token,
                        user: {
                            id: currentUser.uid,
                            email: currentUser.email,
                            firstName: currentUser.displayName?.split(" ")[0],
                            lastName: currentUser.displayName?.split(" ").length > 1 ? currentUser.displayName?.split(" ")[1] : "",
                            name: currentUser.displayName,
                            organisation_id: org_id as string,
                        },
                    },
                })
            } else {
                dispatch({
                    type: "INITIALIZE",
                    payload: {
                        token: "",
                        isAuthenticated: false,
                        user: null,
                    },
                })
            }
        })

        return () => {
            unsubscribe()
        }
    }, [])

    const refreshLogin = async (): Promise<void> => {
        const currentUser = getAuth().currentUser

        if (currentUser) {
            const idTokenResult = await currentUser.getIdTokenResult()
            const token = await getAuth().currentUser.getIdToken()
            const org_id = idTokenResult.claims["organisation_id"]

            dispatch({
                type: "INITIALIZE",
                payload: {
                    token: token,
                    isAuthenticated: true,
                    user: {
                        id: currentUser.uid,
                        email: currentUser.email,
                        firstName: currentUser.displayName?.split(" ")[0],
                        lastName: currentUser.displayName?.split(" ").length > 1 ? currentUser.displayName?.split(" ")[1] : "",
                        name: currentUser.displayName,
                        organisation_id: org_id as string,
                    },
                },
            })
        } else {
            dispatch({
                type: "INITIALIZE",
                payload: {
                    token: "",
                    isAuthenticated: false,
                    user: null,
                },
            })
        }
    }

    const logout = async (): Promise<void> => {
        const auth = getAuth()
        await signOut(auth)
        dispatch({
            type: "LOGOUT",
        })
    }

    const confirmNewMFAFactorAndSignInWithToken = async (
        mfa_factor_id: string,
        challenge_id: string,
        code: string
    ): Promise<void> => {
        // Get customToken to sign in with
        const confirmNewMFAFactorAndSignInWithTokenResult = await authApi.confirmNewMFAFactorAndSignInWithToken({
            mfa_factor_id,
            challenge_id,
            code,
        })
        const auth = getAuth()
        auth.tenantId = confirmNewMFAFactorAndSignInWithTokenResult.tenant_id
        const signInResult = await signInWithCustomToken(auth, confirmNewMFAFactorAndSignInWithTokenResult.custom_token)

        const currentUser = signInResult.user
        const idTokenResult = await currentUser.getIdTokenResult()
        const org_id = idTokenResult.claims["organisation_id"]

        dispatch({
            type: "LOGIN",
            payload: {
                user: {
                    id: currentUser.uid,
                    email: currentUser.email,
                    firstName: currentUser.displayName?.split(" ")[0],
                    lastName: currentUser.displayName?.split(" ").length > 1 ? currentUser.displayName?.split(" ")[1] : "",
                    name: currentUser.displayName,
                    organisation_id: org_id as string,
                },
            },
        })
    }

    // submit login MFA code, receive new token, sign in
    const submitLoginMFACodeAndSignInWithToken = async (challenge_id: string, code: string): Promise<void> => {
        // Get customToken to sign in with
        const verifyMFAResultCustomToken = await authApi.submitLoginMFACodeAndSignInWithToken({
            challenge_id,
            code,
        })
        const auth = getAuth()
        auth.tenantId = verifyMFAResultCustomToken.tenant_id
        const signInResult = await signInWithCustomToken(auth, verifyMFAResultCustomToken.custom_token)

        const currentUser = signInResult.user
        const idTokenResult = await currentUser.getIdTokenResult()
        const org_id = idTokenResult.claims["organisation_id"]

        dispatch({
            type: "LOGIN",
            payload: {
                user: {
                    id: currentUser.uid,
                    email: currentUser.email,
                    firstName: currentUser.displayName?.split(" ")[0],
                    lastName: currentUser.displayName?.split(" ").length > 1 ? currentUser.displayName?.split(" ")[1] : "",
                    name: currentUser.displayName,
                    organisation_id: org_id as string,
                },
            },
        })
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                platform: "Firebase",
                logout,
                submitLoginMFACodeAndSignInWithToken,
                confirmNewMFAFactorAndSignInWithToken,
                refreshLogin,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

AuthProvider.propTypes = {
    children: PropTypes.node.isRequired,
}

export default AuthContext
