export const FormalTableColumnData = {
  APIKeys: [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Created at",
      accessor: "createdAt",
    },
    {
      Header: "",
      accessor: "actions",
    },
  ],
  Policies: [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Description",
      accessor: "description",
    },
    {
      Header: "Created at",
      accessor: "createdAt",
    },
    {
      Header: "ID",
      accessor: "id",
    },
    {
      Header: "",
      accessor: "actions",
    },
  ],
  Metrics: [
    {
      Header: "ID",
      accessor: "id",
    },
    {
      Header: "Datastore ID",
      accessor: "datastore_id",
    },
    {
      Header: "Path",
      accessor: "path",
    },
    {
      Header: "Clear text value",
      accessor: "allow_clear_text_value",
    },
    {
      Header: "Created At",
      accessor: "created_at",
    },
    {
      Header: "",
      accessor: "actions",
    },
  ],
  Users: [
    {
      Header: "Identity",
      accessor: "dbUsername",
    },
    {
      Header: "Type",
      accessor: "type",
    },
    {
      Header: "Date Created",
      accessor: "createdAt",
    },
    {
      Header: "Expire At",
      accessor: "expireAt",
    },
    {
      Header: "",
      accessor: "actions",
    },
  ],
  Sidecars: [
    {
      Header: "Name",
      accessor: "name",
    },
    {
        Header: "Technology",
        accessor: "technology",
    },
    {
      Header: "Sidecar Hostname",
      accessor: "formalHostname",
    },
    {
      Header: "Status",
      accessor: "proxyStatus",
    },
    {
      Header: "Server Connection Status",
      accessor: "serverConnectionStatus",
    },
    {
      Header: "Deployment",
      accessor: "deploymentType",
    },
    {
      Header: "Created On",
      accessor: "createdAt",
    },
    {
      Header: "",
      accessor: "actions",
    },
  ],
  Datastores: [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Sidecar Hostname",
      accessor: "formal_hostname",
    },
    {
      Header: "Status",
      accessor: "proxy_status",
    },
    {
      Header: "Datastore",
      accessor: "datastore_id",
    },
    {
      Header: "Deployment",
      accessor: "deployment_type",
    },
    {
      Header: "Cloud",
      accessor: "cloud_provider",
    },
    {
      Header: "Created On",
      accessor: "created_at",
    },
    {
      Header: "",
      accessor: "actions",
    },
  ],
  DatastoresV2: [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Hostname",
      accessor: "hostname",
    },
    {
      Header: "Technology",
      accessor: "technology",
    },
    {
      Header: "Created On",
      accessor: "createdAt",
    },
    {
      Header: "",
      accessor: "actions",
    },
  ],
  Groups: [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Description",
      accessor: "description",
    },
    {
      Header: "Date Created",
      accessor: "createdAt",
    },
    {
      Header: "",
      accessor: "actions",
    },
  ],
  Apps: [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Type",
      accessor: "type",
    },
    {
      Header: "Details",
      accessor: "details",
    },
    {
      Header: "Date Created",
      accessor: "createdAt",
    },
    {
      Header: "App ID",
      accessor: "id",
    },
    {
      Header: "Linked User ID",
      accessor: "linkedDbUserId",
    },
  ],
  "Field Encryptions": [
    {
      Header: "ID",
      accessor: "id",
    },
    {
      Header: "Field Name",
      accessor: "name",
    },
    {
      Header: "Path",
      accessor: "path",
    },
    {
      Header: "Encryption Algorithm",
      accessor: "alg",
    },
    {
      Header: "Data Key Storage",
      accessor: "keyStorage",
    },
    {
      Header: "Master Encryption Key",
      accessor: "keyId",
    },
  ],
  Inventory: [
    {
      Header: "Datastore",
      accessor: "datastoreName",
    },
    {
      Header: "Path",
      accessor: "path",
      width: 400,
    },
    {
      Header: "Field Name",
      accessor: "name",
    },
    {
      Header: "Type",
      accessor: "dataType",
    },
    {
      Header: "Tags",
      accessor: "tags",
      // width: 200,
    },
    {
      Header: "Label",
      accessor: "dataLabel",
      // width: 300,
    },
    {
      Header: "",
      accessor: "save",
    },
    {
      Header: "Sidecar Label Locked",
      accessor: "dataLabelLockedForSidecar",
      // width: 300,
    },
    {
      Header: "Encryption",
      accessor: "encryption",
    },
    {
      Header: "",
      accessor: "id",
    },
  ],

  DSync: [
    {
      Header: "From the Directory Group",
      accessor: "dgroup",
    },
    {
      Header: "To the Formal Group:",
      accessor: "formal",
    },
  ],
  Keys: [
    {
      Header: "Key Name",
      accessor: "name",
    },
    {
      Header: "Formal ID",
      accessor: "id",
    },
    {
      Header: "KMS Key ID",
      accessor: "keyId",
    },
    {
      Header: "Date Created",
      accessor: "createdAt",
    },
    {
      Header: "Managed by",
      accessor: "managedBy",
    },
    {
      Header: "Active",
      accessor: "active",
    },
    {
      Header: "Key Type",
      accessor: "keyType",
    },
  ],
  CloudAccounts: [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Formal ID",
      accessor: "id",
    },
    {
      Header: "Type",
      accessor: "type",
    },
    {
      Header: "Created On",
      accessor: "createdAt",
    },
    {
      Header: "Actions",
      accessor: "actions",
    },
  ],
  DataDiscoveryDomain: [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Formal ID",
      accessor: "id",
    },
    {
      Header: "Actions",
      accessor: "actions",
    },
  ],
  "External IDs": [
    {
      Header: "External ID",
      accessor: "externalId",
    },
    {
      Header: "App",
      accessor: "appId",
    },
    {
      Header: "Description",
      accessor: "description",
    },
    {
      Header: "Created On",
      accessor: "createdAt",
    },
  ],
  "External Logs": [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Type",
      accessor: "type",
    },
    {
      Header: "Details",
      accessor: "details",
    },
    {
      Header: "Date Created",
      accessor: "createdAt",
    },
    {
      Header: "Integration ID",
      accessor: "id",
    },
  ],
  "Linked Logs": [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Log Type",
      accessor: "type",
    },
    {
      Header: "ID",
      accessor: "id",
    },
    {
      Header: "",
      accessor: "id_copy",
    },
    {
      Header: "Date Linked",
      accessor: "createdAt",
    },
  ],
  "Native Users": [
    {
      Header: "Native User",
      accessor: "nativeUserId",
    },
    {
      Header: "Update Password",
      accessor: "secret",
    },
    {
      Header: "Use for Formal Users",
      accessor: "formal_users",
    },
    {
      Header: "Use for Formal Groups",
      accessor: "formal_groups",
    },
    {
      Header: "",
      accessor: "save_button",
    },
  ],
  Tags: [
    {
      Header: "Tag",
      accessor: "name",
    },
    {
      Header: "Date Created",
      accessor: "createdAt",
    },
    {
      Header: "ID",
      accessor: "id",
    },
  ],
  LinkedSidecars: [
    {
      Header: "Sidecar Name",
      accessor: "name",
    },
    {
      Header: "Sidecar ID",
      accessor: "id",
    },
    {
      Header: "Sidecar Hostname",
      accessor: "hostname",
    },
    {
      Header: "",
      accessor: "actions",
    },
  ],
  LinkedDataStores: [
    {
      Header: "Datastore Name",
      accessor: "datastoreName",
    },
    {
      Header: "Datastore ID",
      accessor: "datastoreId",
    },
    {
        Header: "Port",
        accessor: "port",
    },
    {
      Header: "",
      accessor: "actions",
    },
  ],
  "External APIs": [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Type",
      accessor: "type",
    },
    {
      Header: "URL",
      accessor: "url",
    },
    {
      Header: "Created at",
      accessor: "createdAt",
    },
    {
      Header: "ID",
      accessor: "id",
    },
  ],
  "Datahub Links": [
    {
      Header: "Datastore Id",
      accessor: "datastoreId",
    },
    {
      Header: "Datahub path",
      accessor: "path",
    },
  ],
  "Satellite": [
    {
      Header: "Id",
        accessor: "id",
    },
    {
      Header: "Name",
      accessor: "name",
    },
  ],
}
