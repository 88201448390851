import type { FC } from "react"
import { Link as RouterLink, useLocation } from "react-router-dom"
import PropTypes from "prop-types"
import { Box, Button, Divider, Typography } from "@material-ui/core"
import useAuth from "../../hooks/useAuth"
import StorageIcon from "@material-ui/icons/Storage"
import ListAltIcon from "@material-ui/icons/ListAlt"
import SearchIcon from "@material-ui/icons/Search"
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser"
import FingerprintIcon from "@material-ui/icons/Fingerprint"
import SettingsIcon from "@material-ui/icons/Settings"
import AssessmentIcon from "@mui/icons-material/Assessment"
import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic';

import Logo from "../Logo"
import NavSection from "../NavSection"
import Scrollbar from "../Scrollbar"
import { trackEvent } from "src/analytics"
import { DashboardSidebarReusable } from "./DashboardSidebarReusable"
import {SatelliteAlt} from "@mui/icons-material";

const DOCS_URL = "https://docs.private.joinformal.com/"
interface DashboardSidebarProps {
  customCloseSidebar: () => void
  customSidebarIsOpen: boolean
}

const routesItemsNormalUser = [
  {
    title: "Sidecars",
    path: "/dashboard/sources",
    icon: <StorageIcon fontSize="small" />,
  },
  {
    title: "Data Inventory",
    path: "/dashboard/inventory",
    icon: <ListAltIcon fontSize="small" />,
  },
  // {
  //   title: 'Analytics',
  //   path: '/dashboard/analytics',
  //   icon: <AssessmentIcon fontSize="small" />,
  // },
  {
    title: "Audit",
    path: "/dashboard/audit",
    icon: <SearchIcon fontSize="small" />,
  },
]

const routesItemsAdmin = [
  {
    title: "Sidecars",
    path: "/dashboard/sources",
    icon: <StorageIcon fontSize="small" />,
  },
  {
    title: "Datastores",
    path: "/dashboard/datastores",
    icon: <AutoAwesomeMosaicIcon fontSize="small" />,
  },
  // {
  //   title: "Data Inventory",
  //   path: "/dashboard/inventory",
  //   icon: <ListAltIcon fontSize="small" />,
  // },
  {
    title: "Data Inventory",
    path: "/dashboard/inventory",
    icon: <ListAltIcon fontSize="small" />,
    children: [
      {
        title: "Data Discovery",
        path: "/dashboard/inventory",
      },
      {
        title: "Data Domain",
        path: "/dashboard/inventory/domain",
      },
    ],
  },
  {
    title: "Analytics",
    path: "dashboard/analytics",
    icon: <AssessmentIcon fontSize="small" />,
    children: [
      // {
      //   title: "Metrics",
      //   path: "/dashboard/analytics/metrics",
      // },
      // {
        // title: "Query",
        // path: "/dashboard/analytics/query",
      // },
      {
        title: "Data Flows",
        path: "/dashboard/analytics/flows",
      },
      {
        title: "Row Level Metrics",
        path: "/dashboard/metrics/custom",
      },
    ],
  },
  {
    title: "Policies",
    path: "/dashboard/policies",
    icon: <VerifiedUserIcon fontSize="small" />,
  },
  // {
  //   title: 'Analytics',
  //   path: '/dashboard/analytics',
  // },
  {
    title: "Audit",
    path: "/dashboard/audit",
    icon: <SearchIcon fontSize="small" />,
  },
  {
    title: "Satellites",
    path: "/dashboard/satellites",
    icon: <SatelliteAlt fontSize="small" />,
  },
  // {
  //   title: "Formal Logs",
  //   path: "/dashboard/logs",
  //   icon: <InfoIcon fontSize="small" />,
  // },
  {
    title: "Identities",
    path: "/dashboard/identities",
    icon: <FingerprintIcon fontSize="small" />,
    children: [
      {
        title: "Users",
        path: "/dashboard/identities/users",
      },
      {
        title: "Groups",
        path: "/dashboard/identities/groups",
      },
    ],
  },
  // {
  //   title: 'Workflow',
  //   path: '/dashboard/workflow',
  //   icon: <Bento fontSize="small" />,
  //   children: [
  //     {
  //       title: 'Incidents Management',
  //       path: '/dashboard/workflow/incidents'
  //     },
  //   ]
  // },
  {
    title: "Integrations",
    path: "/dashboard/integrations",
    icon: <SettingsIcon fontSize="small" />,
    children: [
      {
        title: "Cloud Accounts",
        path: "/dashboard/integrations/cloud-providers",
      },
      {
        title: "Encryption Keys",
        path: "/dashboard/integrations/encryption",
      },
      {
        title: "SSO",
        path: "/dashboard/integrations/sso",
      },
      {
        title: "Directory Sync",
        path: "/dashboard/integrations/directory-sync",
      },
      {
        title: "Apps",
        path: "/dashboard/integrations/apps",
      },
      {
        title: 'Logs',
        path: '/dashboard/integrations/logs'
      },
      // {
      //   title: "ChatOps",
      //   path: "/dashboard/integrations/slack",
      // },
      {
        title: "Github",
        path: "/dashboard/integrations/github",
      },
      {
        title: "External APIs",
        path: "/dashboard/integrations/apis",
      },
      {
        title: "Datahub",
        path: "/dashboard/integrations/datahub",
      },
    ],
  },
]

export const DashboardSidebar: FC<DashboardSidebarProps> = (props) => {
  const { customCloseSidebar, customSidebarIsOpen } = props
  const { user } = useAuth() as any
// NOTE this can be changed to useAuth() as AuthState , from import {AuthState} from "@frontegg/redux-store"
  const location = useLocation()
  // const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"))

  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            display: {
              // lg: "none",
              xs: "flex",
            },
            justifyContent: "center",
            p: 2,
          }}
        >
          <RouterLink to={"/dashboard/sources"}>
            <Logo
              sx={{
                height: 40,
                width: 40,
              }}
            />
          </RouterLink>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          <NavSection
            pathname={location.pathname}
            sx={{
              "& + &": {
                mt: 3,
              },
            }}
            items={routesItemsAdmin}
            // items={user.roles.findIndex((r) => r.name === "Admin") !== -1 ? routesItemsAdmin : routesItemsNormalUser}
            customCloseSidebar={customCloseSidebar}
          />
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          <Typography color="textPrimary" variant="subtitle2">
            Need Help?
          </Typography>
          <Typography color="textSecondary" variant="body2">
            Check our docs
          </Typography>
          <Button
            color="primary"
            fullWidth
            sx={{ mt: 2 }}
            variant="contained"
            onClick={() => {
              trackEvent("Documentation", {})
              window.open(DOCS_URL, "_blank")
            }}
          >
            Documentation
          </Button>
        </Box>
      </Scrollbar>
    </Box>
  )

  // if (lgUp) {
  //   return (
  //     <Drawer
  //       anchor="left"
  //       open
  //       PaperProps={{
  //         sx: {
  //           backgroundColor: "background.paper",
  //           height: "calc(100% - 64px) !important",
  //           top: "64px !Important",
  //           width: 280,
  //         },
  //       }}
  //       variant="permanent"
  //     >
  //       {content}
  //     </Drawer>
  //   )
  // }

  return (
    <DashboardSidebarReusable
      side={"left"}
      customSidebarIsOpen={customSidebarIsOpen}
      customCloseSidebar={customCloseSidebar}
      content={content}
      variant="temporary"
    />
    // <Drawer
    //   anchor="left"
    //   onClose={onMobileClose}
    //   open={openMobile}
    //   PaperProps={{
    //     sx: {
    //       backgroundColor: "background.paper",
    //       width: 280,
    //     },
    //   }}
    //   variant="temporary"
    // >
    //   {content}
    // </Drawer>
  )
}

DashboardSidebar.propTypes = {
  customCloseSidebar: PropTypes.func,
  customSidebarIsOpen: PropTypes.bool,
}

export default DashboardSidebar
