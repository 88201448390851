import { useState } from 'react';
import type { FC, ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import { experimentalStyled } from '@material-ui/core/styles';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import ApiKeyModal from "./ApiKeyModal"

interface DashboardLayoutProps {
  children?: ReactNode;
}

const DashboardLayoutRoot = experimentalStyled('div')(
  ({ theme }) => (
    {
      backgroundColor: theme.palette.background.default,
      display: 'flex',
      height: '100%',
      overflow: 'hidden',
      width: '100%'
    }
    )
    );
    
export const TOOLBAR_HEIGHT = "8vh"
export const PAGE_HEIGHT = "92vh"
const DashboardLayoutWrapper = experimentalStyled('div')(
  (
    // { theme }
    ) => (
    {
      display: 'flex',
      flex: '1 1 auto',
      overflow: 'hidden',
      marginTop: TOOLBAR_HEIGHT,
      // [theme.breakpoints.up('lg')]: {
      //   paddingLeft: '280px'
      // }
    }
  )
);

const DashboardLayoutContainer = experimentalStyled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden'
});

const DashboardLayoutContent = experimentalStyled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto',
  position: 'relative',
  WebkitOverflowScrolling: 'touch'
});

const DashboardLayout: FC<DashboardLayoutProps> = () => {
  const [customSidebarIsOpen, setCustomSidebarIsOpen] = useState<boolean>(false);
  const [isApiKeyModalOpen, setApiKeyModalOpen] = useState(false)


  return (
    <DashboardLayoutRoot>
      <DashboardNavbar
        setApiKeyModalOpen={setApiKeyModalOpen}
        customOpenSidebar={(): void => setCustomSidebarIsOpen(true)}
      />
      <DashboardSidebar
        customCloseSidebar={(): void => setCustomSidebarIsOpen(false)}
        customSidebarIsOpen={customSidebarIsOpen}
      />
      <DashboardLayoutWrapper>
        <DashboardLayoutContainer>
          <DashboardLayoutContent>
            <ApiKeyModal 
              openModal={isApiKeyModalOpen} 
              handleClose={() => setApiKeyModalOpen(false)}
            />
            <Outlet />
          </DashboardLayoutContent>
        </DashboardLayoutContainer>
      </DashboardLayoutWrapper>
    </DashboardLayoutRoot>
  );
};

export default DashboardLayout;
