export const firebaseConfig = {
  apiKey: "AIzaSyBW-R7kbpXcDagKDsi91gl2O3xJT3Kns_A",
  authDomain: "consumer-auth.firebaseapp.com",
  projectId: "consumer-auth",
  storageBucket: "consumer-auth.appspot.com",
  messagingSenderId: "1082412479746",
  appId: "1:1082412479746:web:20736c51cde5f482d7be1b"
};

// Env var
// Analytics are not sent if IS_DEV_ENV is true (see analytics.ts). But even if IS_DEV_ENV is set to false during testing,
// Posthog has a "filter out internal and test users" that will filter out those events.
export const IS_DEV_ENV =
  process.env.NODE_ENV === "development" && process.env.REACT_APP_ACTIVATE_PROD_OVERRIDE !== "true" // REACT_APP_ACTIVATE_PROD_OVERRIDE=true means console connects to the prod backend

export const apiConfig = {
  // NOTE: To use Prod API, use `source ./useProdApi.sh` instead
  // baseApiUri: IS_DEV_ENV ? "http://localhost:8080" : "https://api.formalcloud.net", // NOTE: To use Prod API, use `source ./useProdApi.sh` instead
  baseApiUri: "https://api.formalcloud.net"
}

export const IS_DEVELOPING_TURN_OFF_ANALYTICS = process.env.NODE_ENV === "development"
// This is standalone var used to detect if developing (irrespective of dev backend or prod backend) to turn off analytics

export const cohereKey = "Z5jR6eTKMH2xOtF9XJhqcD8x"

export const slacksRedirect = "https://console.joinformal.app/dashboard/integrations/slack"

export const segmentwriteKey = "IM9L8FiERCFMlzrWmkAFYS7SVnH81YDW" // If edited here, needs to be edited in index.html as well

IS_DEV_ENV && console.log("---- IS_DEV_ENV IS TRUE. ----")
