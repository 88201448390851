import { SearchResponse } from "@buf/formal_admin.bufbuild_es/admin/v1/search_pb"
import { ConnectError } from "@bufbuild/connect-web"
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core"
import MaUTable from "@material-ui/core/Table"
import CachedIcon from "@mui/icons-material/Cached"
import type { ChangeEvent, FC, MouseEvent } from "react"
import { useEffect, useMemo, useState } from "react"
import toast from "react-hot-toast"
import { useQuery } from "react-query"
import { usePagination, useRowSelect, useTable } from "react-table"
import { BulkActionButton } from "src/components/common/BulkActionButton"
import useAuth from "../../hooks/useAuth"
import SearchIcon from "../../icons/Search"
import IndeterminateCheckbox from "../Checkbox"
import Scrollbar from "../Scrollbar"

import useDebounce from "src/hooks/useDebounce"
import LoadingOverlay from "../LoadingOverlay"

interface LinkingModalOptions {
  setModalOpen: any
  refetch: any
}

export interface QueryConfig {
  endpoint?: (limit: string, lastKey: string, goBack: boolean) => Promise<any>
  accessor: any
  externalData?: any[]
  getExclusiveStartKey?: (record: any) => string
  commandBarLabelKey?: string
  commandBarContextKey?: string
}
export interface SearchConfig {
  endpoint?: (user: any, query: string, filterBy: string, sortBy: string) => Promise<any[]>
  endpointv2?: (query: string) => Promise<SearchResponse>
  disabled?: boolean
  localSearchProperties?: string[]
  textInstead?: string
}

export interface QueryParams {
  goBack: boolean
  exclusiveStartKey: string
}
export interface ActionConfig {
  handleAction?: (selectedRow: any) => Promise<void> // should delete a single row item.
  // Note that if you use externalData in the queryConfig, your handleAction should refetch. But this will result in many refetches which needs to be fixex
  buttonText: string
  confirmText: string
  subtext?: string

  color: "primary" | "secondary"
  toastMessageSuccess?: string // noe that if you put a toast message in the handleAction, no need to put one here
}

export interface TableConfig {
  removePadding: boolean
}
interface FormalRoundTableDecorationProps {
  linkingModalOptions?: LinkingModalOptions

  columnData: any[]
  renderFunction?: any
  rowRenderFunction?: any
  queryConfig: QueryConfig
  searchConfig: SearchConfig
  actionConfig?: ActionConfig // leave nil to not have selection and action
  utilityButton?: any
  utilityButton2?: any
  refreshButton?: any
  tableConfig?: TableConfig
}

const applyLocalSearch = (items: any[], properties: string[], query: string): any[] => {
  return items.filter((item) => {
    const queryLower = query.toLowerCase()
    for (const property of properties) {
      if (item[property]) {
        if (item[property]?.toLowerCase) {
          if (item[property]?.toLowerCase().includes(queryLower)) {
            return true
          }
        } else if (item[property].toString) {
          if (item[property].toString().toLowerCase().includes(queryLower)) {
            return true
          }
        }
      }
    }

    return false
  })
}
const applyPagination = (items: any[], page: number, pageSize: number): any[] =>
  items.slice(page * pageSize, page * pageSize + pageSize)

const FormalRoundTable: FC<FormalRoundTableDecorationProps> = (props) => {
  const { queryConfig, searchConfig, actionConfig, tableConfig } = props
  const { user } = useAuth()

  const [resultData, setResultData] = useState<any>([])
  const [displayedData, setDisplayedData] = useState<any>([])

  // When this changes, this triggers the query with the new info. GoBack is from what direction of page change (handlePageChange).
  // Without other changes, this value remains the same which allows refetch() to be called to refresh the view (ie after deleting)
  const [queryParams, setQueryParams] = useState<QueryParams>({ goBack: false, exclusiveStartKey: "" })

  // Search
  const [searchQuery, setSearchQuery] = useState<string>("")

  // Keep in mind the notion of page index / size is an independent UI decision albeit one fits the Formal/DDB API well
  const [queryPageIndex, setQueryPageIndex] = useState<number>(0)
  const [queryPageSize, setQueryPageSize] = useState<number>(10)
  const [hasMore, setHasMore] = useState(true)

  const memoizedColumns = useMemo(() => {
    return props.columnData.map((col) => {
      // if (!col.width){
      //   col.width = 50
      // }
      return col
    })
  }, [])

  const defaultColumn = useMemo(
    () => ({
      // When using the useFlexLayout:
      minWidth: 100, // minWidth is only used as a limit for resizing
      // width: 150, // width is used for both the flex-basis and flex-grow
      // maxWidth: 200, // maxWidth is only used as a limit for resizing
    }),
    []
  )

  const {
    isSuccess,
    refetch: _refetch,
    isFetching: _isFetching,
  } = useQuery(
    [queryConfig.accessor, queryParams],
    async () => {
      if (queryConfig.externalData) {
        return { externalData: queryConfig.externalData, has_more: false }
      }
      return await queryConfig.endpoint(
        // We add one (remove one from received data) to force DDB behavior of returning nil lastEvaluatedKey (thus hasMore is false) when theres nothing past item #{queryPageSize}. Default behavior is a true lastEvaluatedKey doesnt guarantee theres something left
        (queryPageSize + 1).toString(),
        queryParams.exclusiveStartKey,
        queryParams.goBack
      )
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        // Has More
        setHasMore(data.has_more)
        let newData = []

        // CommandBar
        if (queryConfig.commandBarContextKey && queryConfig.commandBarLabelKey) {
          window.CommandBar.addContext(queryConfig.commandBarContextKey, data[queryConfig.accessor], {
            renderOptions: {
              labelKey: queryConfig.commandBarLabelKey,
            },
            quickFindOptions: {
              quickFind: true,
            },
          })
        }

        if (queryConfig.externalData) {
          if (searchQuery) {
            refetchSearch()
            return
          } else {
            newData = queryConfig.externalData
          }
        } else {
          newData = data[queryConfig.accessor]?.slice(0, queryPageSize) ?? []
        }
        if (queryParams.goBack) {
          newData.reverse()
        }
        setResultData(newData)
      },
      onError: () => {
        toast.error("Failed to load data.")
      },
    }
  )

  const refetch = useDebounce(_refetch, 500)

  // Refresh if externalData changed (is reloaded for first time)
  useEffect(() => {
    if (props.queryConfig.externalData) {
      refetch() // this call essentialyl loads it again
    }
  }, [props.queryConfig.externalData])

  // Pagination of Data
  const handlePageChange = (event: MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
    setHasMore(false)

    if (!searchQuery) {
      const newQueryParams = { goBack: false, exclusiveStartKey: "" }
      if (queryConfig.externalData) {
        newQueryParams.exclusiveStartKey = Date.now().toString()
      } else {
        if (newPage > queryPageIndex) {
          newQueryParams.goBack = false
          const lastRecord = resultData[resultData.length - 1]
          newQueryParams.exclusiveStartKey = queryConfig.getExclusiveStartKey(lastRecord)
        } else {
          newQueryParams.goBack = true
          const firstRecord = resultData[0]
          newQueryParams.exclusiveStartKey = queryConfig.getExclusiveStartKey(firstRecord)
        }
        setQueryParams(newQueryParams)
      }
    }
    setQueryPageIndex(newPage)
  }

  // If changing limit, then just refresh entire table
  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setQueryPageSize(parseInt(event.target.value, 10))

    // Refetch using new QueryPageSize
    setQueryPageIndex(0)
    setQueryParams({ goBack: false, exclusiveStartKey: "" })
    refetch()
  }

  // ====  Search ====
  const { refetch: _refetchSearch, isFetching: _isFetchingSearch } = useQuery(
    "search" + queryConfig.accessor,
    async (): Promise<any[]> => {
      if (searchConfig.endpointv2 && !searchConfig.disabled) {
        const response = await searchConfig.endpointv2(searchQuery)
        switch (response.result.case) {
          case "users":
            return response.result.value.user
          case "groups":
            return response.result.value.group
          case "sidecars":
            return response.result.value.sidecar
          case "datastores":
            return response.result.value.datastore
          case "policies":
            return response.result.value.policy
          case "inventories":
            return response.result.value.inventory
        }
      }
      if (searchConfig.localSearchProperties) {
        const localFilteredItems = applyLocalSearch(
          queryConfig.externalData,
          searchConfig.localSearchProperties,
          searchQuery
        )
        return Promise.resolve(localFilteredItems)
      }
    },
    {
      enabled: false,
      onSuccess: (data) => {
        setResultData(data)
      },
      onError: (err) => {
        if (err instanceof ConnectError) {
          toast.error("We failed to search your items. Please retry in a minute.")
        } else {
          console.error(err)
        }
      },
    }
  )

  const refetchSearch = useDebounce(_refetchSearch, 500)

  const isFetching = _isFetching || _isFetchingSearch

  // TODO: make memoizedData value diff from resultData so we can use resultData.length as tablecount to show search results
  useEffect(() => {
    if (isSuccess && resultData) {
      // TODO: organize so if{} structure matches pattern of first checking externalData/endpoint, and within searchQuery
      if (!searchQuery) {
        if (!queryConfig.externalData) {
          // Loaded from endpoint
          setDisplayedData(resultData)
        } else {
          // Loaded client side, like ineventory/keys
          const clientSideOnePage = applyPagination(queryConfig.externalData, queryPageIndex, queryPageSize)
          setDisplayedData(clientSideOnePage)
        }
      } else {
        // When there is a search Query, the resultData is all results (whether stored client side or from server)
        let localPaginatedItems = resultData.slice(queryPageIndex * queryPageSize, (queryPageIndex + 1) * queryPageSize)

        // check presence of item # (queryPageSize + 1), presence means theres more
        if (localPaginatedItems.length > queryPageSize) {
          setHasMore(true)
          localPaginatedItems = localPaginatedItems.slice(0, queryPageSize) // remove last one
        } else {
          setHasMore(false)
        }
        setDisplayedData(localPaginatedItems)
      }
    }
  }, [resultData, queryPageIndex])

  const {
    getTableProps,
    rows,
    page,
    state: { selectedRowIds },
    headerGroups,
    prepareRow,
  } = useTable(
    {
      columns: memoizedColumns,
      data: displayedData,
      autoResetHiddenColumns: false,
      autoResetPage: false,
      initialState: {
        pageIndex: queryPageIndex,
        pageSize: queryPageSize,
      },
      manualPagination: true,
      pageCount: -1,
      defaultColumn,
    },
    usePagination,
    useRowSelect,
    // useResizeColumns,
    // useFlexLayout,
    (hooks) => {
      if (!tableConfig?.removePadding) {
        hooks.visibleColumns.push((columns) => [
          // Let's make a column for selection
          {
            id: "selection-col",
            disableResizing: true,
            minWidth: 35,
            width: 35,
            maxWidth: 35,
            Header: () => <div />,
            // Header: ({ getToggleAllRowsSelectedProps }) => (
            //   <div>
            //     <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            //   </div>
            // ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => {
              if (
                row.original.isNew || //new Field Encryption Item
                (!!row.original.key_id && row.original.key_type && !row.original.active) // deactivated key
              ) {
                return <div></div>
              }
              if (actionConfig) {
                return (
                  <div id={row.original.id + "-select"}>
                    {<IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />}
                  </div>
                )
              }
              return <div></div>
            },
          },
          ...columns,
        ])

        hooks.useInstanceBeforeDimensions.push(({ headerGroups }) => {
          // fix the parent group of the selection button to not be resizable
          const selectionGroupHeader = headerGroups[0].headers[0]
          selectionGroupHeader.canResize = false
        })
      }
    }
  )

  const handleQueryChange = (event: ChangeEvent<HTMLInputElement>): void => {
    console.log(event.target.value)
    setQueryPageIndex(0)
    setSearchQuery(event.target.value)
    if (!event.target.value) {
      setQueryParams({ goBack: false, exclusiveStartKey: "" })
    }
  }

  // Trigger the appropriate search based on the searchQuery value changing
  useEffect(() => {
    if (searchQuery) {
      refetchSearch()
    } else {
      // searchQuery changed and is empty, restore normal search
      refetch()
    }
  }, [searchQuery])

  const selectedRows = rows?.filter((row) => selectedRowIds[row.id])

  // Bulk Deletion
  const handleDelete = async (): Promise<void> => {
    await Promise.all(
      selectedRows.map(async (selectedRow) => {
        try {
          return actionConfig.handleAction(selectedRow)
        } catch (e) {
          toast.error(e.response.data.message)
        }
      })
    )
    if (props.linkingModalOptions) {
      props.linkingModalOptions?.refetch()
      props.linkingModalOptions?.setModalOpen(false)
    } else {
      // if externalData is provided, the handleAction() is responsible for using its own refetch
      if (!props.queryConfig.externalData) {
        refetch()
      }
    }
    if (actionConfig.toastMessageSuccess) {
      toast.success(actionConfig.toastMessageSuccess)
    }
  }

  let tableCount = -1

  if (searchQuery) {
    tableCount = resultData.length
  } else {
    // No search query
    if (queryConfig.externalData) {
      // Clientside data
      tableCount = queryConfig.externalData.length
    } else if (!queryParams.goBack && !hasMore) {
      // Last Page of items
      tableCount = queryPageSize * queryPageIndex + displayedData.length
    }
  }

  const searchAndButtonsHeight = "13%"
  let tableHeight = "80%"
  const footerHeight = "7%"

  if (searchConfig.disabled && !actionConfig) {
    tableHeight = "93%"
  }

  return (
    <Box
      sx={{ height: "100%", marginTop: 0, marginBottom: 0, alignSelf: "stretch" }}
      onMouseEnter={() => {
        document.body.style.overscrollBehaviorX = "none"
      }}
      onMouseLeave={() => {
        document.body.style.overscrollBehaviorX = "auto"
      }}
    >
      {!(searchConfig.disabled && !actionConfig) && (
        <Grid container sx={{ height: searchAndButtonsHeight, minHeight: "4rem" }}>
          <Grid
            item
            xs={6}
            sx={{
              height: "100%",
              paddingLeft: 2,
              alignItems: "center",
              display: "flex",
            }}
          >
            {!searchConfig.disabled && (
              <TextField
                // fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon fontSize="small" />
                    </InputAdornment>
                  ),
                }}
                onChange={handleQueryChange}
                placeholder="Search"
                value={searchQuery}
                variant="outlined"
                sx={{ width: "70%" }}
              />
            )}
            {searchConfig.textInstead && (
              <Typography color="textPrimary" variant="h6" sx={{ ml: 1 }}>
                {searchConfig.textInstead}
              </Typography>
            )}
          </Grid>

          <Grid
            item
            xs={6}
            container
            sx={{
              backgroundColor: "#222b36",
              zIndex: 99,
              height: "100%",
              justifyContent: "flex-end",
              display: "flex",
            }}
          >
            {!!props.utilityButton && (
              <Grid item sx={{ display: "flex", alignItems: "center", mr: 2 }}>
                <Button
                  onClick={() => {
                    props.utilityButton.func()
                  }}
                  startIcon={props.utilityButton.startIcon}
                  color="primary"
                  variant={props.utilityButton.variant ?? "contained"}
                  disabled={props.utilityButton.requireSelect ? selectedRows.length !== 1 : false}
                >
                  {props.utilityButton.text}
                </Button>
              </Grid>
            )}

            {!!props.utilityButton2 && (
              <Grid item sx={{ display: "flex", alignItems: "center", mr: 2 }}>
                {props.utilityButton2.component}
              </Grid>
            )}
            {!!actionConfig && selectedRows && (
              <Grid
                item
                sx={{
                  backgroundColor: "background.paper",
                  zIndex: 2,
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  mr: 3,
                }}
              >
                <BulkActionButton
                  actionConfig={actionConfig}
                  deletionFunction={handleDelete}
                  disabled={selectedRows.length === 0}
                />
              </Grid>
            )}

            {!!props.refreshButton && (
              <Grid item sx={{ display: "flex", alignItems: "center", mr: 2 }}>
                <Button
                  onClick={() => {
                    props.refreshButton.func()
                  }}
                  color="primary"
                  variant={"contained"}
                >
                  <CachedIcon fontSize={"medium"} />
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}

      <LoadingOverlay loading={isFetching} />

      <Box sx={{ height: tableHeight, overscrollBehaviorX: "none" }}>
        <Scrollbar>
          <MaUTable {...getTableProps()} stickyHeader>
            <TableHead>
              {headerGroups.map((headerGroup, hIndex) => (
                <TableRow {...headerGroup.getHeaderGroupProps()} key={hIndex}>
                  {headerGroup.headers.map((column) => {
                    return column.hideHeader ? null : (
                      <TableCell
                        key={column.id}
                        {...column.getHeaderProps()}
                        style={{
                          borderTop:
                            column.id === "selection_placeholder_0" ? "none" : "1px solid rgba(145, 158, 171, 0.24)",
                          background: "#222b36",
                        }}
                      >
                        {column.render("Header")}
                      </TableCell>
                    )
                  })}
                </TableRow>
              ))}
            </TableHead>
            <TableBody>
              {page.map((row) => {
                prepareRow(row)
                if (props.rowRenderFunction) {
                  return props.rowRenderFunction(row)
                }
                return (
                  <TableRow {...row.getRowProps()} hover key={row.id}>
                    {row.cells.map((cell, index) => {
                      return props.renderFunction(cell, index)
                    })}
                  </TableRow>
                )
              })}
            </TableBody>
          </MaUTable>
        </Scrollbar>
      </Box>
      <Box sx={{ height: footerHeight }}>
        <TablePagination
          component="div"
          count={tableCount}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={queryPageIndex}
          rowsPerPage={queryPageSize}
          rowsPerPageOptions={[queryPageSize]}
        />
      </Box>
    </Box>
  )
}

export { FormalRoundTable }
