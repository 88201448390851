import { FC } from "react"
import {useNavigate} from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import {useTransport} from "@bufbuild/connect-query";
import {useQuery} from "react-query";
import DomainSummary from "../../components/dashboard/datadiscovery/DomainSummary";
import {
    getDataDomainById
} from "@buf/formal_admin.bufbuild_connect-query/admin/v1/inventory-InventoryService_connectquery";


const DataDiscoveryDomainDetails: FC = () => {

    const navigate = useNavigate()

    const domainId = location.pathname.slice(28)

    const { token } = useAuth()
    const transport = useTransport()

    const { data: domain, isLoading } = useQuery({
        ...getDataDomainById.useQuery({id: domainId}, {
            transport: transport,
            callOptions: {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            }
        }),
        refetchOnWindowFocus: false,
    })



    if (isLoading || !domain) {
        return <div />
    }

    if (!domain) {
        navigate("/dashboard/inventory/domain")
    }
    return (
        <DomainSummary domain={domain.domain}/>
    )
}

export default DataDiscoveryDomainDetails
