import { FC } from 'react';
import { CircularProgress } from '@material-ui/core';

type Props = {
  loading?: boolean;
};

const LoadingOverlay: FC<Props> = ({ loading = true }) => {
  if (!loading) {
    return null;
  }

  return (
    <div
      style={{
        zIndex: 99,
        position: 'fixed',
        inset: 0,
        background: 'rgba(0, 0, 0, 0.3)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress />
    </div>
  );
};

export default LoadingOverlay;
