import type { FC } from "react"
import { QueryClient, useMutation, useQuery } from "react-query"
import React, { useState } from "react"
import { User } from "@buf/formal_admin.bufbuild_es/admin/v1/types/v1/user_pb"
import useAuth from "../../../hooks/useAuth"
import { useTransport } from "@bufbuild/connect-query"
import { Group } from "@buf/formal_admin.bufbuild_es/admin/v1/types/v1/group_pb"
import { TextField, Button, IconButton, Box, Grid, Typography, Card } from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"
import Select from "react-select"
import { InputLabel, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core"
import { useNavigate } from "react-router"
import { createDataDomain } from "@buf/formal_admin.bufbuild_connect-query/admin/v1/inventory-InventoryService_connectquery"
import toast from "react-hot-toast"
import {listGroups} from "@buf/formal_admin.bufbuild_connect-query/admin/v1/identities-GroupService_connectquery";
import {listUsers} from "@buf/formal_admin.bufbuild_connect-query/admin/v1/identities-UserService_connectquery";

const CreateDomainModal: FC = () => {
  const [users, setUsers] = useState<User[]>([])
  const [groups, setGroups] = useState<Group[]>([])
  const [inputIncluded, setInputIncluded] = useState("")
  const [listIncluded, setListIncluded] = useState([])
  const [inputExcluded, setInputExcluded] = useState("")
  const [listExcluded, setListExcluded] = useState([])
  const [domainName, setDomainName] = useState<string>("")
  const [description, setDescription] = useState<string>("")
  const [selectedOptions, setSelectedOptions] = useState([])

  const { token } = useAuth()
  const transport = useTransport()
  const queryClient = new QueryClient()
  const navigate = useNavigate()

  const createDomainMutation = useMutation({
    ...createDataDomain.useMutation({
      transport: transport,
      callOptions: {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
      onError: (error) => {
        console.error(error)
        toast.error(error.message)
      },
    }),
    onSuccess: async () => {
      await queryClient.invalidateQueries("domains")
      navigate("/dashboard/inventory/domain")
    },
  })

  const getUsersQuery = listUsers.createUseQueryOptions(
    {},
    {
      transport: transport,
      callOptions: {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    }
  )
  const getGroupsQuery = listGroups.createUseQueryOptions(
    { limit: 1000 },
    {
      transport: transport,
      callOptions: {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    }
  )

  useQuery(
    "users",
    async () => {
      const data = await getUsersQuery.queryFn()
      return data.users
    },
    {
      onSuccess: (data) => {
        setUsers(data)
      },
    }
  )
  useQuery(
    "groups",
    async () => {
      const data = await getGroupsQuery.queryFn()
      return data.groups
    },
    {
      onSuccess: (data) => {
        setGroups(data)
      },
    }
  )

  const userList = users.map((user) => ({
    label: user.name ? user.name : user.firstName + " " + user.lastName,
    value: user.id,
    objectType: "user",
  }))
  const groupList = groups.map((group) => ({ label: group.name, value: group.id, objectType: "group" }))

  const usersAndGroups = [...userList, ...groupList]
  const handleCreate = async () => {
    const owners = selectedOptions.map((item) => ({ objectId: item.value, objectType: item.objectType }))

    createDomainMutation.mutate({
      name: domainName,
      description: description,
      includedPaths: listIncluded,
      excludedPaths: listExcluded,
      owners: owners,
    })
  }

  return (
    <Grid container spacing={3}>
      <Grid item md={12} xs={12}>
        <Card>
          <Box sx={{ p: 3, display: "flex", overflow: "visible" }}>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  label="Domain Name"
                  name="domain_name"
                  sx={{ mt: 3 }}
                  FormHelperTextProps={{
                    sx: {
                      textAlign: "right",
                      mr: 0,
                    },
                  }}
                  fullWidth
                  rows={5}
                  onChange={(e) => setDomainName(e.target.value)}
                  value={domainName}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  label="Description"
                  name="description"
                  sx={{ mt: 3 }}
                  FormHelperTextProps={{
                    sx: {
                      textAlign: "right",
                      mr: 0,
                    },
                  }}
                  fullWidth
                  rows={5}
                  onChange={(e) => setDescription(e.target.value)}
                  value={description}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <InputLabel sx={{ mb: 2 }} id="owmer">
                  Select Data Owners
                </InputLabel>
                <Select
                  isMulti
                  variant="outlined"
                  classNamePrefix="react-select"
                  labelId={"owner"}
                  menuPortalTarget={document.body}
                  menuPosition={"fixed"}
                  value={selectedOptions}
                  sx={{ mt: 1, width: "100%", color: "#fff", overflow: "visible" }}
                  options={usersAndGroups}
                  onChange={setSelectedOptions}
                  styles={{
                    control: (baseStyles) => ({
                      ...baseStyles,
                      color: "#fff",
                      backgroundColor: "#222B36",
                    }),
                    singleValue: (baseStyles) => ({
                      ...baseStyles,
                      color: "#fff",
                      backgroundColor: "#222B36",
                    }),
                    multiValue: (provided) => {
                      return { ...provided, backgroundColor: "#688EFF", zIndex: 999 }
                    },
                    option: (base, state) => ({
                      ...base,
                      backgroundColor: state.isFocused ? "#2684FF" : "#171c24",
                      zIndex: 9999,
                      color: "#fff",
                    }),
                    menuPortal: (base) => ({ ...base, backgroundColor: "#171c24", zIndex: 9999 }),
                    menu: (provided) => ({
                      ...provided,
                      backgroundColor: "#171c24",
                      color: "#fff",
                    }),
                  }}
                ></Select>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Grid>
      <Grid item md={6} xs={12}>
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Typography align="center" color="textPrimary" gutterBottom variant="h6">
            Included paths
          </Typography>

          <Box display="flex" justifyContent="space-between" mb={2} width="100%">
            <TextField
              variant="outlined"
              placeholder="Enter path..."
              value={inputIncluded}
              onChange={(e) => setInputIncluded(e.target.value)}
              fullWidth
            />

            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                if (inputIncluded !== "") {
                  setListIncluded((oldList) => [...oldList, inputIncluded])
                  setInputIncluded("")
                }
              }}
              style={{ marginLeft: "10px" }}
            >
              Add
            </Button>
          </Box>

          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Path</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {listIncluded.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item}</TableCell>
                    <TableCell align="right">
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => setListIncluded((oldList) => oldList.filter((item, indx) => indx !== index))}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Grid>
      <Grid item md={6} xs={12}>
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Typography align="center" color="textPrimary" gutterBottom variant="h6">
            Excluded paths
          </Typography>

          <Box display="flex" justifyContent="space-between" mb={2} width="100%">
            <TextField
              variant="outlined"
              placeholder="Enter path..."
              value={inputExcluded}
              onChange={(e) => setInputExcluded(e.target.value)}
              fullWidth
            />

            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                if (inputExcluded !== "") {
                  setListExcluded((oldList) => [...oldList, inputExcluded])
                  setInputExcluded("")
                }
              }}
              style={{ marginLeft: "10px" }}
            >
              Add
            </Button>
          </Box>

          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Path</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {listExcluded.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item}</TableCell>
                    <TableCell align="right">
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => setListExcluded((oldList) => oldList.filter((item, indx) => indx !== index))}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Grid>{" "}
      <Grid item md={12} xs={12}>
        <Box display="flex" justifyContent="flex-end" mt={3}>
          <Button variant="contained" color="primary" onClick={handleCreate}>
            Create
          </Button>
        </Box>
      </Grid>
    </Grid>
  )
}

export default CreateDomainModal
