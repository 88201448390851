import axios from 'axios';

import { apiConfig } from '../config';
import {getAuth} from "firebase/auth";

const { baseApiUri } = apiConfig;

class CordApi {
    async getClientAuthToken(): Promise<string> {
        const token = await getAuth().currentUser.getIdToken()

        const result = await axios.get(`${baseApiUri}/admin/cord`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
  
      return Promise.resolve(result.data.token);
    }


}

export const cordApi = new CordApi();
